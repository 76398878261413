<template>
    <div>
        <button v-for="child in allChildren"
                :key="`child-${child.id}`"
                @click="click(child)"
                class="flex pb-2 w-full items-center hover:font-semibold"
                :class='child.id === currentChild.id ? "font-bold" : ""'>
            <font-awesome-icon v-if="child.id === currentChild.id" class="mr-2 fa-sm" icon="chevron-right" />
            <span>{{ child.title }}</span>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        allChildren: Array,
        currentChild: Object
    },
    methods: {
        click(child) {
            this.$emit('setFilterChild', child);
        }
    }
};
</script>
