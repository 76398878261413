var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [_c("BlockComponentSelector", { attrs: { model: _vm.model } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }