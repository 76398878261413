var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultTemplate", { attrs: { model: _vm.model } }, [
    _c("div", { staticClass: "bg-gray-100 pb-4" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("Breadcrumbs", {
            attrs: {
              breadcrumbs: _vm.model.breadcrumbs,
              className: "text-grey-400",
            },
          }),
          _vm._v(" "),
          _vm.inEditMode
            ? _c(
                "div",
                { staticClass: "container" },
                [
                  _c("EpiProperty", {
                    attrs: {
                      propertyName: "RegistrationButtonText",
                      displayText: "Knappetekst ved påmelding mulig",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "bg-white p-5 mt-1.5" }, [
              _c("h1", [_vm._v(_vm._s(_vm.model.name))]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-full mt-4" },
            [
              _c(
                "Tabs",
                {
                  staticClass: "bg-white",
                  attrs: {
                    selectedTabIndex: _vm.selectedTabIndex,
                    storageKey: _vm.storageKey,
                  },
                },
                [
                  _c(
                    "Tab",
                    {
                      attrs: {
                        title: "Kurs og konferanser",
                        icon: "chalkboard",
                      },
                    },
                    [
                      _vm.regularCourses.length > 0
                        ? _c("div", { staticClass: "md:flex mt-2" }, [
                            _c("div", { staticClass: "w-full" }, [
                              _c("div", { staticClass: "md:flex flex-col" }, [
                                _c(
                                  "ol",
                                  { staticClass: "bg-white" },
                                  _vm._l(_vm.regularCourses, function (result) {
                                    return _c(
                                      "li",
                                      {
                                        key: result.url,
                                        staticClass:
                                          "p-4 border-2 m-2 hover:shadow-md shadow-sm",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              href: result.Url,
                                              title: result.Title,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "w-full lg:w-3/4",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "bg-white py-5 my-1.5 flex h-full",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "md:w-1/12 w-1/4 md:border-r-2 md:pr-4",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex flex-col justify-around text-center h-full w-full",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-gray-600",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    result.Year
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-4xl text-red-400",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    result.Day
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-gray-600",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    result.Month
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "md:w-11/12 w-3/4 md:pl-4 flex flex-col",
                                                      },
                                                      [
                                                        _c(
                                                          "h3",
                                                          {
                                                            staticClass:
                                                              "md:flex md:justify-between text-2xl mb-2 md:items-start font-semibold pt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                                " +
                                                                _vm._s(
                                                                  result.Title
                                                                ) +
                                                                "\n                                                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        result.RegistrationDeadline
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pt-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                Påmeldingsfrist: " +
                                                                    _vm._s(
                                                                      result.RegistrationDeadline
                                                                    ) +
                                                                    "\n                                                            "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        result.Location
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pt-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon: "map-marker",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(
                                                                  "\n                                                                " +
                                                                    _vm._s(
                                                                      result.Location
                                                                    ) +
                                                                    "\n                                                            "
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                            ]),
                          ])
                        : _c(
                            "div",
                            { staticClass: "m-4 pb-4" },
                            [
                              _c("message-box", {
                                attrs: {
                                  title: "Ingen kurs",
                                  text: "Det finnes for øyeblikket ingen kurs og konferanser",
                                  type: "info",
                                },
                              }),
                            ],
                            1
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "Tab",
                    {
                      attrs: {
                        title: "Tilbud på forespørsel",
                        icon: "question",
                      },
                    },
                    [
                      _vm.onDemandCourses.length > 0
                        ? _c("div", { staticClass: "md:flex mt-2" }, [
                            _c("div", { staticClass: "w-full" }, [
                              _c("div", { staticClass: "md:flex flex-col" }, [
                                _c(
                                  "ol",
                                  { staticClass: "bg-white" },
                                  _vm._l(
                                    _vm.onDemandCourses,
                                    function (result) {
                                      return _c(
                                        "li",
                                        {
                                          key: result.url,
                                          staticClass:
                                            "p-4 border-2 m-2 hover:shadow-md shadow-sm",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "cursor-pointer",
                                              attrs: {
                                                href: result.Url,
                                                title: result.Title,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-full lg:w-3/4",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "bg-white py-5 my-1.5 flex h-full",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "md:w-1/12 w-1/4 text-4xl text-red-400 md:border-r-2 md:pr-4",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex flex-col justify-around h-full w-full",
                                                            },
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "chalkboard-teacher",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "md:w-11/12 w-3/4 md:pl-4 flex flex-col",
                                                        },
                                                        [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "md:flex md:justify-between text-base mb-2 md:items-start font-semibold pt-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                " +
                                                                  _vm._s(
                                                                    result.Title
                                                                  ) +
                                                                  "\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          result.Duration
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pt-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                                Varighet: " +
                                                                      _vm._s(
                                                                        result.Duration
                                                                      ) +
                                                                      "\n                                                            "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          result.Location
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pt-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: "map-marker",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                                                                " +
                                                                      _vm._s(
                                                                        result.Location
                                                                      ) +
                                                                      "\n                                                            "
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ])
                        : _c(
                            "div",
                            { staticClass: "m-4 pb-4" },
                            [
                              _c("message-box", {
                                attrs: {
                                  title: "Ingen tilbud på forespørsel",
                                  text: "Det finnes for øyeblikket ingen tilbud på forespørsel",
                                  type: "info",
                                },
                              }),
                            ],
                            1
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "Tab",
                    { attrs: { title: "Kursmateriell", icon: "file" } },
                    [
                      _vm.passedCourses.length > 0
                        ? _c("div", { staticClass: "md:flex mt-2" }, [
                            _c("div", { staticClass: "w-full" }, [
                              _c("div", { staticClass: "md:flex flex-col" }, [
                                _c(
                                  "ol",
                                  { staticClass: "bg-white" },
                                  _vm._l(_vm.passedCourses, function (result) {
                                    return _c(
                                      "li",
                                      {
                                        key: result.url,
                                        staticClass:
                                          "p-4 border-2 m-2 hover:shadow-md shadow-sm",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              href: result.Url,
                                              title: result.Title,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "w-full lg:w-3/4",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "bg-white py-5 my-1.5 flex h-full",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "md:w-1/12 w-1/4 md:border-r-2 md:pr-4",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex flex-col justify-around text-center h-full w-full",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-gray-600",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    result.Year
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-4xl text-red-400",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    result.Day
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-gray-600",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    result.Month
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "md:w-11/12 w-3/4 md:pl-4 flex flex-col",
                                                      },
                                                      [
                                                        _c(
                                                          "h3",
                                                          {
                                                            staticClass:
                                                              "md:flex md:justify-between text-base mb-2 md:items-start font-semibold pt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                                " +
                                                                _vm._s(
                                                                  result.Title
                                                                ) +
                                                                "\n                                                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        result.Location
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pt-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon: "map-marker",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(
                                                                  "\n                                                                " +
                                                                    _vm._s(
                                                                      result.Location
                                                                    ) +
                                                                    "\n                                                            "
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        result.Documentation
                                                          .length > 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "flex pt-2",
                                                              },
                                                              _vm._l(
                                                                result.Documentation,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: item.href,
                                                                      staticClass:
                                                                        "md:w-1/3 w-full",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              href: item.Url,
                                                                              target:
                                                                                item.target,
                                                                              title:
                                                                                item.text,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "flex flex-wrap",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-red-400 hover:text-gray-400",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "font-awesome-icon",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-top":
                                                                                            "6px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "file-pdf",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-red-400 hover:text-gray-400 underline truncate ml-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                                " +
                                                                                      _vm._s(
                                                                                        item.Text
                                                                                      ) +
                                                                                      "\n                                                                            "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                            ]),
                          ])
                        : _c(
                            "div",
                            { staticClass: "m-4 pb-4" },
                            [
                              _c("message-box", {
                                attrs: {
                                  title: "Ingen kursdokumentasjon",
                                  text: "Det finnes for øyeblikket ingen kursdokumentasjon tilgjengelig",
                                  type: "info",
                                },
                              }),
                            ],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }