var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "div",
            { staticClass: "block lg:hidden p-6 text-lg font-medium" },
            [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
          ),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }