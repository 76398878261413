import { render, staticRenderFns } from "./SliderBlock.vue?vue&type=template&id=435225d4&scoped=true&"
import script from "./SliderBlock.vue?vue&type=script&lang=js&"
export * from "./SliderBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "435225d4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\src\\KoRus.Nord\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('435225d4')) {
      api.createRecord('435225d4', component.options)
    } else {
      api.reload('435225d4', component.options)
    }
    module.hot.accept("./SliderBlock.vue?vue&type=template&id=435225d4&scoped=true&", function () {
      api.rerender('435225d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Assets/Scripts/components/blocks/SliderBlock/SliderBlock.vue"
export default component.exports