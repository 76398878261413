var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "border-b border-tundora-300 text-tundora-100 text-sm" },
    [
      _vm.item.children.length === 0
        ? _c("div", { staticClass: "flex flex-col w-full" }, [
            _c("div", { staticClass: "flex hover:bg-tundora-400" }, [
              _c("div", { staticClass: "w-1/12" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "block py-4 px-2 flex w-11/12",
                  attrs: { href: _vm.item.url },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.item.text) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.children.length > 0
        ? _c("div", { staticClass: "flex flex-col w-full" }, [
            _c("div", { staticClass: "flex w-full hover:bg-tundora-400" }, [
              _c("div", { staticClass: "py-4 px-2 cursor-pointer w-1/12" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.showChildren = !_vm.showChildren
                      },
                    },
                  },
                  [
                    !_vm.showChildren
                      ? _c("font-awesome-icon", {
                          staticClass: "fa-fw",
                          attrs: { icon: "plus" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showChildren
                      ? _c("font-awesome-icon", {
                          staticClass: "fa-fw",
                          attrs: { icon: "minus" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "block py-4 px-2 flex w-11/12",
                  attrs: { href: _vm.item.url },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.item.text) +
                      "\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.showChildren
              ? _c(
                  "div",
                  { staticClass: "flex flex-col w-full" },
                  _vm._l(_vm.item.children, function (child) {
                    return _c("Item", {
                      key: child.url,
                      attrs: { item: child },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }