var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.model.articles
    ? _c("div", [
        _c("div", { staticClass: "mx-0 mt-10 mb-2" }, [
          _c(
            "div",
            { staticClass: "bg-white lg:bg-transparent slider" },
            [
              _c(
                "swiper",
                { ref: "slider", attrs: { options: _vm.swiperOptions } },
                [
                  _vm._l(_vm.model.articles, function (article) {
                    return _c(
                      "swiper-slide",
                      {
                        key: article.id,
                        staticClass: "flex items-center justify-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "relative" },
                          [
                            article.url
                              ? _c(
                                  "EpiLink",
                                  {
                                    attrs: {
                                      url: article.url,
                                      title: `Les mer om ${article.heading}`,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: `${
                                          article.image
                                        }?${_vm.getImageParams()}`,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "relative w-full lg:p-8 pt-4 text-black bg-white lg:absolute lg:bottom-0 lg:top-0 lg:right-20 lg:w-4/12 lg:bg-opacity-80",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-lg md:text-2xl lg:text-3xl",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pb-4 text-lg border-b-2",
                                              },
                                              [
                                                _c("h2", [
                                                  _vm._v(_vm._s(article.name)),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass:
                                                "h-32 py-4 text-base overflow-hidden",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  article.ingress
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "inline-block mt-4 md:btn-sm md:mt-6 underline text-blue-600 hover:text-red-600",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(article.buttonIcon) +
                                                "\n                                    Les mer →\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.model.articles.length > 1
                    ? _c("div", {
                        staticClass:
                          "hidden lg:block swiper-button-prev text-blue-500 mr-8",
                        attrs: { slot: "button-prev", id: "sliderPrevButton" },
                        slot: "button-prev",
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.articles.length > 1
                    ? _c("div", {
                        staticClass:
                          "hidden lg:block swiper-button-next text-blue-500 ml-8",
                        attrs: { slot: "button-next", id: "sliderNextButton" },
                        slot: "button-next",
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.model.articles.length > 1
            ? _c("div", {
                staticClass: "relative pb-4 flex justify-center",
                attrs: { slot: "pagination", id: "sliderPagination" },
                slot: "pagination",
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.model.articlesRoot && _vm.model.articles.length > 0
            ? _c("div", { staticClass: "flex justify-center mt-6" }, [
                _c("a", { attrs: { href: _vm.model.articlesRoot.value.url } }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-base cursor-pointer underline text-blue-600 hover:text-red-500",
                    },
                    [_vm._v("Se alle saker")]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }