<template>
    <div class="justify-around flex flex-row items-center">
        <epi-link class="inline-block text-white opacity-75 hover:opacity-100 mr-3"
                  url="https://www.facebook.com/korusnord"
                  target="_new"
                  title="Facebook (åpnes i ny fane)"
                  content="Facebook (åpnes i ny fane)" v-tippy>
            <font-awesome-icon class="fa-fw" :icon="[ 'fab', 'facebook-square' ]" />
        </epi-link>
        <button class="inline-block text-white opacity-75 hover:opacity-100 mr-3"
                @click="print()"
                content="Skriv ut denne siden" v-tippy>
            <font-awesome-icon class="fa-fw" icon="print" />
        </button>
        <button class="inline-block text-white opacity-75 hover:opacity-100 mr-3" id="speaker"
                onClick="vFactClient.leseAct()"
                content="Lytt til tekst" v-tippy>
            <font-awesome-icon class="fa-fw" icon="volume-up" />
        </button>
        <div class="inline-block text-white opacity-75 hover:opacity-100 mr-3 cursor-pointer"
             content="Hold Ctrl-tasten nede (Cmd på Mac).<br/>Trykk på + for å forstørre eller - for å forminske." v-tippy>
            <font-awesome-icon class="fa-fw" icon="text-height" />
        </div>
        <a :href="model.login.href" :target="model.login.target" :title="model.login.text" class="inline-block text-white opacity-75 hover:opacity-100 mr-3" v-tippy>
            <font-awesome-icon class="fa-fw" :icon="model.login.icon" />
        </a>
    </div>
</template>

<script>
import EpiLink from '@/Scripts/components/atoms/EpiLink/index.vue';

export default {
    props: ['model'],
    components: {
        EpiLink,
    },
    methods: {
        print() {
            window.print();
        }
    },
};
</script>
