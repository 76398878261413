<template>
    <div class="relative mb-10">
        <h2 class="uppercase text-xl font-semibold tracking-wide text-gray-600 bg-white z-20 relative inline-block ml-10 px-6">
            {{text}}
        </h2>
        <div class="bg-gray-300 h-1 w-full absolute top-3 left-0 z-10">
        </div>
    </div>
</template>

<script>
export default {
    props: ['text'],
};
</script>
