<template>
    <DefaultTemplate :model="model">
        <div v-if="inEditMode" class="container">
            <EpiProperty propertyName="Header" displayText="Header" />
            <EpiProperty propertyName="Footer" displayText="Footer" />
            <EpiProperty propertyName="SearchPageRefernce" displayText="Søkeside" />
            <EpiProperty propertyName="LatestMagazine" displayText="Siste magasin" />
            <EpiProperty propertyName="CourseListRoot" displayText="Kursliste" />
            <EpiProperty propertyName="ArticleRoot" displayText="Artikkelliste" />
            <EpiProperty propertyName="MagazineRoot" displayText="Magasinliste" />
            <EpiProperty propertyName="RequestCourseRoot" displayText="Tilbud på forespørsel-liste" />
        </div>

        <!-- Artikkel slider / main content -->
        <div class="container mb-12">
            <HighlightedArticle v-if="model.highlightedArticle" :article="model.highlightedArticle" :url="model.highlightedArticleUrl" />
            <ContentArea v-epi-edit="'MainContent'" :model="model.mainContent" />
        </div>

        <!-- Visjon/Misjon/Oppdrag -->
        <TabImage color="lightblue" location="left" />
        <div class="bg-blue-100">
            <div class="container pt-12 pb-12 text-xl">
                <div v-if="model.visionText" v-epi-edit="'VisionText'" id="vision-text" class="main-body my-4 article pt-4" v-html="model.visionText" />
            </div>
        </div>

        <!-- Kurs og konferanser -->
        <TabImage color="darkblue" location="right" />
        <div class="bg-blue-500">
            <div style=" background: url('/assets/images/nord/group.png') repeat-x 0px 190px;">
                <div class="container pt-12">
                    <div class="text-3xl text-white text-center main-body my-2 article pt-4 pb-1">Kurs og konferanser</div>
                    <div class="flex">
                        <div class="text-right w-full right">
                            <a class="text-base cursor-pointer underline text-blue-300 hover:text-red-300" :href="model.courseListUrl">Se alle kurs og konferanser</a>
                        </div>
                        <span class="mx-4 text-white">//</span>
                        <div class="text-left w-full left">
                            <a class="text-base cursor-pointer underline text-blue-300 hover:text-red-300" :href="model.courseDocumentationListUrl">Kursmateriell</a>
                        </div>
                    </div>
                    <CourseSlider :courses="model.courses" />
                </div>
                <div class="container pt-12 pb-20">
                    <div class="text-3xl text-white text-center main-body my-2 article pt-4 pb-1">Tilbud på forespørsel</div>
                    <div class="w-full text-center">
                        <a class="text-base cursor-pointer underline text-blue-300 hover:text-red-300" :href="model.requestCourseListUrl">Se alle tilbud på forespørsel</a>
                    </div>
                    <CourseOnDemandSlider :courses="model.coursesOnRequest" />
                </div>
            </div>
        </div>

        <!-- Våre satsninger mot kommuner og spesialhelsetjenesten -->
        <TabImage color="white" location="left" />
        <div class="bg-white">
            <div class="container pt-12 pb-12">
                <div class="sm:text-3xl text-base text-center main-body my-4 article pt-4 uppercase">Våre satsninger rettet mot kommuner og spesialisthelsetjenesten</div>
                <div v-if="inEditMode">
                    <EpiProperty propertyName="ThemePages" displayText="Temasider" />
                </div>
                <ul v-if="model.themePages" class="flex flex-wrap content-evenly md:mb-12 mb-6">
                    <li v-for="page in model.themePages" :key="page.id" class="xl:w-1/3 md:w-1/2 w-full">
                        <div class="border-2 m-1 relative group h-full">
                            <div class="p-6">
                                <a :href="page.url" target="_self">
                                    <span class="text-xl font-semibold cursor-pointer hover:text-red-500 hover:underline">{{page.heading}}</span>
                                </a>
                            </div>
                            <div class="py-2">
                                <img :v-if="lineImage" :src="lineImage" class="object-fill w-full" />
                            </div>
                            <div v-if="page.links" class="p-6">
                                <div v-for="link in page.links" :key="link.id">
                                    <a :href="link.url" target="_self">
                                        <div class="flex mb-2">
                                            <img :v-if="bulletImage" :src="bulletImage" class="mr-2 h-4 w-4" />
                                            <span class="text-base cursor-pointer hover:text-red-500 hover:underline">{{link.name}}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Magasiner -->
        <TabImage color="lightblue" location="right" />
        <div class="bg-blue-100">
            <div class="container pt-12 pb-12">
                <div class="text-3xl text-center main-body my-4 article pt-4">Magasinet SPOR</div>
                <div v-if="inEditMode">
                    <EpiProperty propertyName="MagazineContent" displayText="Magasinsider" />
                </div>
                <div class="w-full text-center mb-8 mt-2">
                    <a class="text-base cursor-pointer underline text-blue-600 hover:text-red-500" :href="model.magazineListUrl">Se alle publikasjoner</a>
                </div>
                <ul class="flex flex-wrap justify-center text-left content-evenly mb-10">
                    <li v-for="magazine in model.magazines" :key="magazine.id" class="p-8">
                        <div class="shadow-md hover:shadow-sm rounded relative group h-full">
                            <a :href="magazine.url">
                                <img :src="`${magazine.image}?scale=both&width=220&height=310&mode=crop&anchor=middelcenter`" />
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Lenker til forebygging og kommunetorget -->
        <TabImage color="white" location="left" />
        <div class="bg-white">
            <div class="container pt-12 pb-12">
                <div class="main-body my-4 article pt-4">
                    <div class="flex flex-row flex-wrap">
                        <div class="lg:w-1/2 xl:pl-40 xl:pr-40 pl-10 pr-10">
                            <a href="https://www.forebygging.no" target="_blank">
                                <img src="/Assets/Images/Nord/forebygging-logo.jpg" class="hover:shadow-md" />
                            </a>
                            <div class="text-base text-center pl-10 pr-10 md:pl-2 md:pr-2">
                                er en kunnskapsbase for rusforebyggende og helsefremmende arbeid
                            </div>
                        </div>
                        <div class="lg:w-1/2 xl:pl-40 xl:pr-40 pl-10 pr-10">
                            <a href="https://www.kommunetorget.no" target="_blank">
                                <img src="/Assets/Images/Nord/kommunetorget-logo.jpg" class="hover:shadow-md" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </DefaultTemplate>
</template>

<script>
import HighlightedArticle from '@/Scripts/components/organisms/Highlighted/HighlightedArticle.vue';
import ContentArea from '@/Scripts/components/ContentArea.vue';
import Container from '@/Scripts/components/atoms/Container/index.vue';
import EpiProperty from '@/Scripts/components/EpiProperty.vue';
import TabImage from '@/Scripts/components/organisms/TabImage/tabImage.vue';
import { mapState } from 'vuex';
import CourseSlider from '@/Scripts/Components/Organisms/slider/courseSlider.vue';
import CourseOnDemandSlider from '@/Scripts/Components/Organisms/slider/courseOnDemandSlider.vue';

export default {
    props: ['model'],
    computed: mapState({
        inEditMode: state => state.epiContext.inEditMode
    }),
    data() {
        return {
            lineImage: '/Assets/Images/Nord/grafisk_linje_400.png',
            bulletImage: '/Assets/Images/Nord/bull-spes.png',
            prevButton1: 'prevButton1',
            prevButton2: 'prevButton2',
            nextButton1: 'nextButton1',
            nextButton2: 'nextButton2',
            sliderPagination1: 'sliderPagination1',
            sliderPagination2: 'sliderPagination2',
        };
    },
    components: {
        HighlightedArticle,
        ContentArea,
        Container,
        EpiProperty,
        TabImage,
        CourseSlider,
        CourseOnDemandSlider,
    },
};
</script>
