var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modelLoaded
    ? _c(
        "div",
        [
          _c(_vm.getComponentTypeForPage(_vm.model), {
            tag: "component",
            attrs: { model: _vm.model },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }