var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultTemplate", { attrs: { model: _vm.model } }, [
    _c("div", { staticClass: "bg-gray-100 pb-10" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("Breadcrumbs", {
            attrs: {
              breadcrumbs: _vm.model.breadcrumbs,
              className: "text-black",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "bg-white p-5 my-1.5" }, [
              _c("div", [
                _c(
                  "form",
                  {
                    staticClass: "flex mb-4",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.search()
                      },
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.query,
                          expression: "query",
                        },
                      ],
                      staticClass: "w-full bg-gray-100 flex-1 px-3",
                      domProps: { value: _vm.query },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.query = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "py-2 px-4 text-white uppercase bg-orange-500 hover:bg-orange-300",
                        attrs: { type: "submit" },
                      },
                      [_c("font-awesome-icon", { attrs: { icon: "search" } })],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.loading
            ? _c("LoadingSpinner", { attrs: { size: "2x" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.error && !_vm.loading
            ? _c("message-box", {
                attrs: {
                  title: "Feil",
                  text: "En feil har oppstått i forsøket på å søke. Hvis feilen gjentar seg, vennligst ta kontakt med oss for hjelp til å finne det innholdet du leter etter.",
                  type: "alert",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.searchResult.length === 0 && _vm.query && !_vm.loading
            ? _c("message-box", {
                attrs: {
                  title: "Ingen treff",
                  text: "Vi kan ikke finne noen treff i det du søker etter",
                  type: "info",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.searchResult.length > 0 && !_vm.loading
            ? _c("div", { staticClass: "md:flex mt-2" }, [
                _c("div", { staticClass: "w-full" }, [
                  _c(
                    "div",
                    { staticClass: "md:flex flex-col" },
                    [
                      _c(
                        "ol",
                        { staticClass: "divide-y bg-white" },
                        _vm._l(_vm.searchResult, function (result) {
                          return _c(
                            "li",
                            { key: result.Url, staticClass: "p-4" },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "md:flex md:justify-between text-base mb-2 md:items-start",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-blue-500 underline hover:text-red-500",
                                      attrs: {
                                        href: result.Url,
                                        title: result.Name,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(result.Name) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                domProps: { innerHTML: _vm._s(result.extract) },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      Math.ceil(
                        _vm.searchResultCount / _vm.pagination.pages.pageSize
                      ) > 1
                        ? _c("Pagination", {
                            attrs: {
                              currentPage: _vm.pagination.pages.page,
                              totalPages: Math.ceil(
                                _vm.searchResultCount /
                                  _vm.pagination.pages.pageSize
                              ),
                            },
                            on: { change: _vm.paginatePages },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }