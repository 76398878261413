var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container relative -top-10 max-h-0" }, [
    _vm.location === "left"
      ? _c("div", { staticClass: "flex flex-row" }, [
          _vm.color === "lightblue"
            ? _c("div", { staticClass: "bg-blue-100 w-28 h-28 rounded-full" })
            : _vm._e(),
          _vm._v(" "),
          _vm.color === "darkblue"
            ? _c("div", { staticClass: "bg-blue-500 w-28 h-28 rounded-full" })
            : _vm._e(),
          _vm._v(" "),
          _vm.color === "white"
            ? _c("div", { staticClass: "bg-white w-28 h-28 rounded-full" })
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.location === "right"
      ? _c("div", { staticClass: "flex flex-row-reverse" }, [
          _vm.color === "lightblue"
            ? _c("div", { staticClass: "bg-blue-100 w-28 h-28 rounded-full" })
            : _vm._e(),
          _vm._v(" "),
          _vm.color === "darkblue"
            ? _c("div", { staticClass: "bg-blue-500 w-28 h-28 rounded-full" })
            : _vm._e(),
          _vm._v(" "),
          _vm.color === "white"
            ? _c("div", { staticClass: "bg-white w-28 h-28 rounded-full" })
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }