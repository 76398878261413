var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultTemplate",
    { staticClass: "bg-gray-100", attrs: { model: _vm.model } },
    [
      _c(
        "div",
        { staticClass: "container pb-4" },
        [
          _c("Breadcrumbs", {
            attrs: {
              breadcrumbs: _vm.model.breadcrumbs,
              className: "text-grey-400",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "block lg:flex" }, [
            _c("div", { staticClass: "w-full lg:w-3/4 pr-0 lg:pr-8" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "Name",
                      expression: "'Name'",
                    },
                  ],
                  staticClass: "py-5 mt-1.5",
                },
                [_c("h1", [_vm._v(_vm._s(_vm.model.heading))])]
              ),
              _vm._v(" "),
              _vm.model.image || _vm.inEditMode
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Image",
                          expression: "'Image'",
                        },
                      ],
                    },
                    [
                      _c("img", {
                        staticClass: "w-full",
                        attrs: {
                          src: `${_vm.model.image}?width=1152`,
                          alt: _vm.model.imageDescription,
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.image || _vm.inEditMode
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "ImageDescription",
                          expression: "'ImageDescription'",
                        },
                      ],
                    },
                    [
                      _vm.model.imageDescription
                        ? _c("p", {
                            staticClass: "text-xs",
                            domProps: {
                              textContent: _vm._s(_vm.model.imageDescription),
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.ingress || _vm.inEditMode
                ? _c("div", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Ingress",
                        expression: "'Ingress'",
                      },
                    ],
                    staticClass: "main-body my-4 article pt-4",
                    attrs: { id: "ingress" },
                    domProps: { innerHTML: _vm._s(_vm.model.ingress) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.model.mainBody || _vm.inEditMode
                ? _c("div", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "MainBody",
                        expression: "'MainBody'",
                      },
                    ],
                    staticClass: "main-body my-4 article pt-4",
                    attrs: { id: "main-body" },
                    domProps: { innerHTML: _vm._s(_vm.model.mainBody) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mt-4 flex" }, [
                _vm.model.published
                  ? _c("span", { staticClass: "py-2 text-sm text-grey-300" }, [
                      _vm._v(
                        "Publisert: " + _vm._s(_vm.model.published) + ", "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.lastChanged
                  ? _c("span", { staticClass: "py-2 text-sm text-grey-300" }, [
                      _vm._v("endret: " + _vm._s(_vm.model.lastChanged)),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-4", attrs: { if: "inEditMode" } },
                [
                  _c("EpiProperty", {
                    attrs: {
                      propertyName: "ShowList",
                      displayText: "Vis aktuelle saker",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.model.showList
                ? _c("div", [
                    _c("div", { staticClass: "py-5 mt-6 border-t" }, [
                      _c("h2", [_vm._v("Aktuelle saker")]),
                    ]),
                    _vm._v(" "),
                    _vm.model.articles
                      ? _c(
                          "ul",
                          _vm._l(_vm.model.articles, function (article) {
                            return _c(
                              "li",
                              {
                                key: article.id,
                                staticClass:
                                  "flex mb-4 bg-white border relative",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "absolute top-0 left-0 h-full w-full",
                                    attrs: {
                                      href: article.url,
                                      title: "Les mer",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(
                                        "\n                                    Les mer\n                                "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "w-1/4 py-4 md:py-0" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: `${
                                          article.image
                                        }?${_vm.getImageParams()}`,
                                        alt: article.name,
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-3/4 p-4" }, [
                                  _c("h3", [_vm._v(_vm._s(article.name))]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "py-2 text-sm text-grey-300",
                                      },
                                      [
                                        _vm._v(
                                          "[Publisert " +
                                            _vm._s(article.published) +
                                            "]"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", {
                                      staticClass: "text-sm pb-2",
                                      domProps: { innerHTML: article.ingress },
                                    }),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "aside",
              {
                staticClass: "w-full lg:w-1/4 pr-0 lg:pl-4 mb-4 md:pt-14 pt-6",
              },
              [
                _c("div", { staticClass: "py-5 mt-1.5" }, [
                  _vm.model.imageRight || _vm.inEditMode
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "ImageRight",
                              expression: "'ImageRight'",
                            },
                          ],
                        },
                        [
                          _c("img", {
                            staticClass: "w-full",
                            attrs: { src: _vm.model.imageRight },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.relevantCourses || _vm.inEditMode
                    ? _c("div", { staticClass: "mt-4 bg-white" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "RelevantCoursesHeading",
                                expression: "'RelevantCoursesHeading'",
                              },
                            ],
                            staticClass: "text-xl p-4 uppercase mb-2",
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.model.relevantCoursesHeading) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "RelevantCourses",
                                expression: "'RelevantCourses'",
                              },
                            ],
                          },
                          _vm._l(_vm.model.relevantCourses, function (item) {
                            return _vm.model.relevantCourses.length > 0
                              ? _c(
                                  "li",
                                  {
                                    key: item.id,
                                    staticClass: "py-2 px-4 bg-white",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.url,
                                          target: "_self",
                                          title: item.name,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex flex-row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex flex-col flex-wrap truncate mr-2",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-grey-300 text-xs",
                                                  },
                                                  [_vm._v(_vm._s(item.date))]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-grey-500 hover:text-red-500 hover:underline",
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-grey-300 text-xs",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.location)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "bg-gray-300 h-px my-2",
                                    }),
                                  ]
                                )
                              : _vm._e()
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.model.courseListUrl
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "w-full text-right p-4 text-grey-500 hover:text-red-500 hover:underline",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.model.courseListUrl,
                                      target: "_self",
                                    },
                                  },
                                  [_vm._v("Vis kursoversikt →")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.resourcePages || _vm.inEditMode
                    ? _c("div", { staticClass: "mt-4" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "ResourcePagesHeading",
                                expression: "'ResourcePagesHeading'",
                              },
                            ],
                            staticClass: "font-semibold mb-2 mt-4",
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.model.resourcePagesHeading) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "bg-gray-300 h-px my-2" }),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "ResourcePages",
                                expression: "'ResourcePages'",
                              },
                            ],
                          },
                          _vm._l(_vm.model.resourcePages, function (item) {
                            return _vm.model.resourcePages.length > 0
                              ? _c(
                                  "li",
                                  { key: item.id, staticClass: "pt-2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-grey-500 hover:text-red-500",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: item.url,
                                              target: item.target,
                                              title: item.text,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "flex flex-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(item.text) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e()
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.resourceDocuments || _vm.inEditMode
                    ? _c("div", { staticClass: "mt-4" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "ResourceDocumentsHeading",
                                expression: "'ResourceDocumentsHeading'",
                              },
                            ],
                            staticClass: "font-semibold mb-2 mt-4",
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.model.resourceDocumentsHeading) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "bg-gray-300 h-px my-2" }),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "ResourceDocuments",
                                expression: "'ResourceDocuments'",
                              },
                            ],
                          },
                          _vm._l(_vm.model.resourceDocuments, function (item) {
                            return _vm.model.resourceDocuments.length > 0
                              ? _c(
                                  "li",
                                  { key: item.id, staticClass: "pt-2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-grey-500 hover:text-red-500",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: item.url,
                                              target: item.target,
                                              title: item.text,
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "flex" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticStyle: {
                                                      "margin-top": "6px",
                                                    },
                                                    attrs: { icon: "file-alt" },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(item.text) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e()
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-4", attrs: { if: "inEditMode" } },
                    [
                      _c("EpiProperty", {
                        attrs: {
                          propertyName: "ShowArchive",
                          displayText: "Vis arkiv",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.model.showArchive || _vm.inEditMode
                    ? _c("div", [
                        _vm.model.archive || _vm.inEditMode
                          ? _c("div", { staticClass: "mt-4" }, [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "epi-edit",
                                      rawName: "v-epi-edit",
                                      value: "ArchiveHeading",
                                      expression: "'ArchiveHeading'",
                                    },
                                  ],
                                  staticClass: "font-semibold mb-2 mt-4",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.model.archiveHeading) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "bg-gray-300 h-px my-2",
                              }),
                              _vm._v(" "),
                              _vm.model.archive.length > 0
                                ? _c("div", [
                                    _c(
                                      "ul",
                                      _vm._l(
                                        _vm.model.archive,
                                        function (item) {
                                          return _c(
                                            "li",
                                            {
                                              key: item.id,
                                              staticClass: "pt-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-grey-500 hover:text-red-500",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: item.url,
                                                        target: item.target,
                                                        title: item.name,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                            },
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "caret-right",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    item.name
                                                                  ) +
                                                                  "\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }