<template>
    <div class="border shadow-lg hover:shadow-md bg-white rounded-b-lg relative my-10 lg:mt-20 lg:mb-20">
        <div class="border-t-8 border-blue-500 p-8 lg:p-12 lg:flex lg:items-center">
            <div class="mr-12 mb-6 lg:mb-0 text-center lg:text-left w-full lg:w-1/4">
                <img
                    :src="imageUrl"
                    :alt="article.heading"
                    class="mx-auto"
                />
            </div>
            <div class="w-full lg:w-3/4">
                <h3
                    class="uppercase text-gray-600 mb-4 text-sm"
                >
                    Aktuelt
                </h3>
                <h2
                    v-html="article.heading"
                    class="font-bold text-gray-600 text-2xl mb-2"
                ></h2>
                <p
                    v-html="article.extract"
                    class="leading-6"
                ></p>
            </div>
        </div>
        <a
            :href="article.url"
            :title="article.heading"
            class="absolute w-full h-full top-0 left-0 cursor-pointer"
        >
            <span class="sr-only">
                Les mer
            </span>
        </a>
    </div>
</template>

<script>
export default {
    props: ['article'],
    computed: {
        imageUrl() {
            return this.article.image + '?width=638&height=425&' + this.article.imageResizerParams;
        }
    }

};
</script>
