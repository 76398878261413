<template>
    <div v-if='isActive' class="mt-5">
        <div class="block lg:hidden p-6 text-lg font-medium">
            {{title}}
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            isActive: true
        };
    },
};
</script>
