<template>
    <div class="container relative -top-10 max-h-0">
        <div v-if="location === 'left'" class="flex flex-row">
            <div v-if="color === 'lightblue'" class="bg-blue-100 w-28 h-28 rounded-full" />
            <div v-if="color === 'darkblue'" class="bg-blue-500 w-28 h-28 rounded-full" />
            <div v-if="color === 'white'" class="bg-white w-28 h-28 rounded-full" />
        </div>
        <div v-if="location === 'right'" class="flex flex-row-reverse">
            <div v-if="color === 'lightblue'" class="bg-blue-100 w-28 h-28 rounded-full" />
            <div v-if="color === 'darkblue'" class="bg-blue-500 w-28 h-28 rounded-full" />
            <div v-if="color === 'white'" class="bg-white w-28 h-28 rounded-full" />
        </div>
    </div>
</template>

<script>

export default {
    props: {
        color: {
            type: String,
            required: true
        },
        location: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            darkblue: '/Assets/Images/Nord/tab-db.png',
            lightblue: '/Assets/Images/Nord/tab-lb.png',
            white: '/Assets/Images/Nord/tab-wh.png'
        };
    },
};
</script>
