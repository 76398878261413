var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultTemplate", { attrs: { model: _vm.model } }, [
    _c("div", { staticClass: "bg-gray-100 pb-10" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("Breadcrumbs", {
            attrs: {
              breadcrumbs: _vm.model.breadcrumbs,
              className: "text-grey-400",
            },
          }),
          _vm._v(" "),
          _vm.inEditMode
            ? _c(
                "div",
                { staticClass: "container" },
                [
                  _c("EpiProperty", {
                    attrs: {
                      propertyName: "RegistrationButtonText",
                      displayText: "Knappetekst ved påmelding mulig",
                    },
                  }),
                  _vm._v(" "),
                  _c("EpiProperty", {
                    attrs: {
                      propertyName: "RegistrationPastButtonText",
                      displayText: "Knappetekst ved påmeldingsfrist utløpt",
                    },
                  }),
                  _vm._v(" "),
                  _c("EpiProperty", {
                    attrs: { propertyName: "Tag", displayText: "Tag" },
                  }),
                  _vm._v(" "),
                  _c("EpiProperty", {
                    attrs: {
                      propertyName: "OnDemand",
                      displayText: "Kurs på forespørsel",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "bg-white p-5 mt-1.5" }, [
              _c(
                "h1",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "CourseTitle",
                      expression: "'CourseTitle'",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.model.courseTitle))]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "block lg:flex" }, [
            _c("div", { staticClass: "w-full lg:w-3/4" }, [
              _c("div", { staticClass: "bg-white p-5 my-1.5 flex h-full" }, [
                _c(
                  "div",
                  { staticClass: "w-1/12 text-4xl text-red-500 border-r-2" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-col justify-around h-full w-full",
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "chalkboard-teacher" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "w-11/12 pl-4 flex flex-col" }, [
                  !_vm.model.onDemand
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "StartDate",
                              expression: "'StartDate'",
                            },
                          ],
                        },
                        [_vm._v("Kursdato: " + _vm._s(_vm.model.startDate))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Duration",
                          expression: "'Duration'",
                        },
                      ],
                    },
                    [_vm._v("Varighet: " + _vm._s(_vm.model.duration))]
                  ),
                  _vm._v(" "),
                  _vm.model.onDemand
                    ? _c("div", [_vm._v("Tidspunkt: etter avtale")])
                    : _c("div", [
                        !_vm.model.pastRegistrationDate
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "epi-edit",
                                      rawName: "v-epi-edit",
                                      value: "RegistrationDeadline",
                                      expression: "'RegistrationDeadline'",
                                    },
                                  ],
                                },
                                [
                                  _vm._v(
                                    "Påmeldingsfrist: " +
                                      _vm._s(_vm.model.registrationDeadline)
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Location",
                          expression: "'Location'",
                        },
                      ],
                    },
                    [_vm._v("Sted: " + _vm._s(_vm.model.location))]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full lg:w-1/4" }, [
              _c("div", { staticClass: "bg-white p-5 my-1.5 flex h-full" }, [
                !_vm.model.onDemand
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-col justify-around h-full w-full",
                      },
                      [
                        !_vm.model.pastRegistrationDate
                          ? _c("div", [
                              _vm.model.registrationUrl
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mx-10 bg-orange-500 hover:bg-orange-300 text-center text-white font-semibold p-4 rounded cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.open(
                                            _vm.model.registrationUrl
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "epi-edit",
                                              rawName: "v-epi-edit",
                                              value: "RegistrationButtonText",
                                              expression:
                                                "'RegistrationButtonText'",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.model.registrationButtonText
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm.model.pastRegistrationDate
                          ? _c("div", [
                              _vm.model.registrationUrl
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mx-10 bg-gray-400 text-center text-white font-semibold p-4 rounded",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "epi-edit",
                                              rawName: "v-epi-edit",
                                              value:
                                                "RegistrationPastButtonText",
                                              expression:
                                                "'RegistrationPastButtonText'",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.model
                                                  .registrationPastButtonText
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-full mt-4" },
            [
              _c(
                "Tabs",
                {
                  staticClass: "bg-white",
                  attrs: { selectedTabIndex: _vm.tabIndex },
                },
                [
                  _vm.model.description || _vm.inEditMode
                    ? _c(
                        "Tab",
                        {
                          attrs: {
                            title: "Kursbeskrivelse",
                            icon: "quote-right",
                          },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "Description",
                                expression: "'Description'",
                              },
                            ],
                            staticClass: "main-body my-4 article p-6",
                            domProps: {
                              innerHTML: _vm._s(_vm.model.description),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.agenda || _vm.inEditMode
                    ? _c(
                        "Tab",
                        { attrs: { title: "Program", icon: "calendar-alt" } },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "Agenda",
                                expression: "'Agenda'",
                              },
                            ],
                            staticClass: "main-body my-4 article p-6",
                            domProps: { innerHTML: _vm._s(_vm.model.agenda) },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.practicalInformation || _vm.inEditMode
                    ? _c(
                        "Tab",
                        {
                          attrs: {
                            title: "Praktiske opplysninger",
                            icon: "map-marker",
                          },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "PracticalInformation",
                                expression: "'PracticalInformation'",
                              },
                            ],
                            staticClass: "main-body my-4 article p-6",
                            domProps: {
                              innerHTML: _vm._s(_vm.model.practicalInformation),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.lecturer || _vm.inEditMode
                    ? _c(
                        "Tab",
                        {
                          attrs: {
                            title: "Foreleser",
                            icon: "chalkboard-teacher",
                          },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "Lecturer",
                                expression: "'Lecturer'",
                              },
                            ],
                            staticClass: "main-body my-4 article p-6",
                            domProps: { innerHTML: _vm._s(_vm.model.lecturer) },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.price || _vm.inEditMode
                    ? _c(
                        "Tab",
                        { attrs: { title: "Kursavgift", icon: "dollar-sign" } },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "Price",
                                expression: "'Price'",
                              },
                            ],
                            staticClass: "main-body my-4 article p-6",
                            domProps: { innerHTML: _vm._s(_vm.model.price) },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.approval || _vm.inEditMode
                    ? _c(
                        "Tab",
                        {
                          attrs: {
                            title: "Godkjenning",
                            icon: "graduation-cap",
                          },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "Approval",
                                expression: "'Approval'",
                              },
                            ],
                            staticClass: "main-body my-4 article p-6",
                            domProps: { innerHTML: _vm._s(_vm.model.approval) },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.documentation.length > 0 || _vm.inEditMode
                    ? _c(
                        "Tab",
                        { attrs: { title: "Dokumentasjon", icon: "file" } },
                        [
                          _c(
                            "dl",
                            {
                              directives: [
                                {
                                  name: "epi-edit",
                                  rawName: "v-epi-edit",
                                  value: "Documentation",
                                  expression: "'Documentation'",
                                },
                              ],
                              staticClass: "main-body my-4 article p-6",
                            },
                            _vm._l(_vm.model.documentation, function (item) {
                              return _c(
                                "dd",
                                { key: item.title, staticClass: "pt-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-grey-400 hover:text-green-500",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: item.url,
                                            target: item.target,
                                            title: item.text,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "flex" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-red-400 hover:text-gray-400",
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticStyle: {
                                                    "margin-top": "6px",
                                                  },
                                                  attrs: { icon: "file-pdf" },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-red-400 hover:text-gray-400 underline truncate ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(item.text) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }