var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-12 mb-20" }, [
    _vm.dataLoaded
      ? _c(
          "div",
          [
            _c("LinedHeader", { attrs: { text: _vm.lineText } }),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass:
                  "md:flex md:flex-wrap text-left content-evenly -m-2",
              },
              _vm._l(_vm.results, function (result) {
                return _c(
                  "li",
                  {
                    key: result.url,
                    staticClass: "w-full md:w-1/2 xl:w-1/4 p-2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-white shadow-md hover:shadow-sm rounded relative group h-full",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: `${result.ImageUrl}?${_vm.imageParams}&${result.ImageResizerParams}`,
                            alt: result.Name,
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "p-4" }, [
                          _c("h3", { staticClass: "text-base font-bold" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(result.Name) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("a", {
                          staticClass: "absolute w-full h-full top-0 left-0",
                          attrs: { href: result.Url, title: result.Name },
                        }),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.latestPublishedPageUrl
              ? _c(
                  "div",
                  { staticClass: "text-right my-4 w-full" },
                  [
                    _c(
                      "EpiLink",
                      {
                        attrs: {
                          url: _vm.latestPublishedPageUrl,
                          className:
                            "inline-block text-lg underline text-blue-500 hover:text-blue-600",
                        },
                      },
                      [_vm._v("\n                Vis flere ❯\n            ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _c("div", [_c("LoadingSpinner", { attrs: { size: "2x" } })], 1),
    _vm._v(" "),
    _vm.error ? _c("div", [_vm._v("Noe gikk galt")]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }