var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative mb-10" }, [
    _c(
      "h2",
      {
        staticClass:
          "uppercase text-xl font-semibold tracking-wide text-gray-600 bg-white z-20 relative inline-block ml-10 px-6",
      },
      [_vm._v("\n        " + _vm._s(_vm.text) + "\n    ")]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "bg-gray-300 h-1 w-full absolute top-3 left-0 z-10",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }