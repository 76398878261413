var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tagType,
    {
      tag: "component",
      staticClass: "EPiLink",
      class: _vm.className,
      attrs: {
        to: _vm.url,
        href: _vm.url,
        target: _vm.target,
        title: _vm.title,
        "aria-label": _vm.ariaLabel,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }