var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Page-container GenericBlock" }, [
    _c("div", { staticClass: "Grid Grid--alignMiddle Grid--gutterA" }, [
      _c("div", { staticClass: "Grid-cell" }, [
        _c("p", [
          _vm._v("Could not load "),
          _c("i", [_vm._v(_vm._s(_vm.model.name))]),
          _vm._v(" vue component."),
        ]),
        _vm._v("\n            " + _vm._s(_vm.log(_vm.model)) + "\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }