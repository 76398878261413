var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "bg-white print:hidden" }, [
    _c("div", { staticClass: "c-top-menu__container bg-tundora-600" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass: "c-top-menu py-2 flex md:flex-wrap md:content-center",
          },
          [
            _c(
              "div",
              { staticClass: "w-1/4" },
              [_c("Menu", { attrs: { menu: _vm.model.menu } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-3/4 flex justify-end" },
              [
                _c("Topmenu", { attrs: { model: _vm.model.header } }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "md:hidden inline-block text-white opacity-75 hover:opacity-100 mr-3 cursor-pointer",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.showMobileSearch = !_vm.showMobileSearch
                      },
                    },
                  },
                  [_c("font-awesome-icon", { attrs: { icon: "search" } })],
                  1
                ),
                _vm._v(" "),
                _c("Search", {
                  staticClass: "hidden md:flex",
                  attrs: {
                    searchPage: _vm.model.searchPage,
                    className: `w-52`,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.showMobileSearch
      ? _c(
          "div",
          {
            staticClass:
              "flex justify-end w-full py-2 md:hidden bg-tundora-600",
          },
          [
            _c("Search", {
              attrs: {
                searchPage: _vm.model.searchPage,
                className: `w-full mr-4`,
                defaultFocus: true,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "c-header py-5" }, [
        _c("div", { staticClass: "flex flex-row justify-between" }, [
          _c("div", { staticClass: "flex mt-6" }, [
            _c("a", { attrs: { href: "/" } }, [
              _c("img", {
                attrs: {
                  "v-if": _vm.model.header.mainLogoUrl,
                  src: _vm.model.header.mainLogoUrl,
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex items-center" }, [
            _c("a", { staticClass: "flex justify-end", attrs: { href: "/" } }, [
              _c("img", {
                attrs: {
                  "v-if": _vm.model.header.mainImageUrl,
                  src: _vm.model.header.mainImageUrl,
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }