<template>
    <div>
        <h3 class="uppercase text-base mb-4">
            Kontakt oss
        </h3>
        <div class="flex flex-row items-start mb-4">
            <div class="pr-2">
                <font-awesome-icon class="fa-lg" icon="building" />
            </div>
            <div class="flex flex-col">
                <div>{{model.address}}</div>
                <div>{{model.zipAndCity}}</div>
            </div>
        </div>

        <div class="flex flex-row items-start mb-4">
            <div class="pr-2">
                <font-awesome-icon class="fa-lg" icon="phone-alt" />
            </div>
            <div class="flex flex-col">
                <a :href="model.phone.href" class="underline text-white hover:text-blue-500">
                    <span class="underline text-white hover:text-red-500">
                        {{model.phone.text}}
                    </span>
                </a>
            </div>
        </div>

        <div class="flex flex-row items-start mb-4">
            <div class="pr-2">
                <font-awesome-icon class="fa-lg" icon="envelope" />
            </div>
            <div class="flex flex-col">
                <a :href="model.email.href" class="underline text-white hover:text-blue-500">
                    <span class="underline text-white hover:text-red-500">
                        {{model.email.text}}
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['model'],
};
</script>
