<template>
    <DefaultTemplate :model="model">
        <div class="bg-gray-100 pb-10">
            <div class="container">
                <Breadcrumbs :breadcrumbs="model.breadcrumbs" className="text-grey-400" />
                <div v-if="inEditMode" class="container">
                    <EpiProperty propertyName="RegistrationButtonText" displayText="Knappetekst ved påmelding mulig" />
                    <EpiProperty propertyName="RegistrationPastButtonText" displayText="Knappetekst ved påmeldingsfrist utløpt" />
                    <EpiProperty propertyName="Tag" displayText="Tag" />
                    <EpiProperty propertyName="OnDemand" displayText="Kurs på forespørsel" />
                </div>
                <div class="w-full">
                    <div class="bg-white p-5 mt-1.5 ">
                        <h1 v-epi-edit="'CourseTitle'">{{model.courseTitle}}</h1>
                    </div>
                </div>
                <div class="block lg:flex">
                    <div class="w-full lg:w-3/4">
                        <div class="bg-white p-5 my-1.5 flex h-full">
                            <div class="w-1/12 text-4xl text-red-500 border-r-2">
                                <div class="flex flex-col justify-around h-full w-full">
                                    <font-awesome-icon icon="chalkboard-teacher" />
                                </div>
                            </div>
                            <div class="w-11/12 pl-4 flex flex-col">
                                <span v-if="!model.onDemand" v-epi-edit="'StartDate'">Kursdato: {{model.startDate}}</span>
                                <span v-epi-edit="'Duration'">Varighet: {{model.duration}}</span>
                                <div v-if="model.onDemand">Tidspunkt: etter avtale</div>
                                <div v-else>
                                    <div v-if="!model.pastRegistrationDate">
                                        <span v-epi-edit="'RegistrationDeadline'">Påmeldingsfrist: {{model.registrationDeadline}}</span>
                                    </div>
                                </div>
                                <span v-epi-edit="'Location'">Sted: {{model.location}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="w-full lg:w-1/4">
                        <div class="bg-white p-5 my-1.5 flex h-full">
                            <div v-if="!model.onDemand" class="flex flex-col justify-around h-full w-full">
                                <div v-if="!model.pastRegistrationDate">
                                    <div v-if="model.registrationUrl" class="mx-10 bg-orange-500 hover:bg-orange-300 text-center text-white font-semibold p-4 rounded cursor-pointer" v-on:click="open(model.registrationUrl)">
                                        <span v-epi-edit="'RegistrationButtonText'">
                                            {{model.registrationButtonText}}
                                        </span>
                                    </div>
                                </div>
                                <div v-else-if="model.pastRegistrationDate">
                                    <div v-if="model.registrationUrl" class="mx-10 bg-gray-400 text-center text-white font-semibold p-4 rounded">
                                        <span v-epi-edit="'RegistrationPastButtonText'">
                                            {{model.registrationPastButtonText}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full mt-4">
                    <Tabs class="bg-white" :selectedTabIndex="tabIndex">
                        <Tab v-if="model.description || inEditMode" title="Kursbeskrivelse" icon="quote-right">
                            <div v-epi-edit="'Description'" class="main-body my-4 article p-6" v-html="model.description" />
                        </Tab>
                        <Tab v-if="model.agenda || inEditMode" title="Program" icon="calendar-alt">
                            <div v-epi-edit="'Agenda'" class="main-body my-4 article p-6" v-html="model.agenda" />
                        </Tab>
                        <Tab v-if="model.practicalInformation || inEditMode" title="Praktiske opplysninger" icon="map-marker">
                            <div v-epi-edit="'PracticalInformation'" class="main-body my-4 article p-6" v-html="model.practicalInformation" />
                        </Tab>
                        <Tab v-if="model.lecturer || inEditMode" title="Foreleser" icon="chalkboard-teacher">
                            <div v-epi-edit="'Lecturer'" class="main-body my-4 article p-6" v-html="model.lecturer" />
                        </Tab>
                        <Tab v-if="model.price || inEditMode" title="Kursavgift" icon="dollar-sign">
                            <div v-epi-edit="'Price'" class="main-body my-4 article p-6" v-html="model.price" />
                        </Tab>
                        <Tab v-if="model.approval || inEditMode" title="Godkjenning" icon="graduation-cap">
                            <div v-epi-edit="'Approval'" class="main-body my-4 article p-6" v-html="model.approval" />
                        </Tab>
                        <Tab v-if="model.documentation.length > 0 || inEditMode" title="Dokumentasjon" icon="file">
                            <dl v-epi-edit="'Documentation'" class="main-body my-4 article p-6">
                                <dd v-for="item in model.documentation" :key="item.title" class="pt-2">
                                    <div class="text-grey-400 hover:text-green-500">
                                        <a :href="item.url" :target="item.target" :title="item.text">
                                            <div class="flex">
                                                <div class="text-red-400 hover:text-gray-400">
                                                    <font-awesome-icon style="margin-top: 6px;" icon="file-pdf" />
                                                </div>
                                                <div class="text-red-400 hover:text-gray-400 underline truncate ml-2">
                                                    {{item.text}}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </dd>
                            </dl>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import { mapState } from 'vuex';
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import Tab from '@/Scripts/components/atoms/Tabs/Tab.vue';
import Tabs from '@/Scripts/components/atoms/Tabs/Tabs.vue';
import EpiProperty from '@/Scripts/components/EpiProperty.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
        Tab,
        Tabs,
        EpiProperty,
    },
    data() {
        return {
            tabIndex: this.getTabIndex(),
        };
    },
    computed: mapState({
        inEditMode: state => state.epiContext.inEditMode
    }),
    methods: {
        open(url) {
            window.open(url, '_blank');
        },
        getTabIndex() {
            if (this.$route.query.tab === undefined) {
                return '0';
            } else {
                return this.$route.query.tab;
            }
        },
    },
};
</script>
