<template>
    <DefaultTemplate :model="model" class="bg-gray-100">
        <div class="container">
            <Breadcrumbs :breadcrumbs="model.breadcrumbs" className="text-grey-400" />
            <div class="block lg:flex">
                <div class="w-full lg:w-3/4 pr-0 lg:pr-8">
                    <!-- Overskrift -->
                    <div v-epi-edit="'Name'" class="py-5 mt-1.5 ">
                        <h1>{{model.heading}}</h1>
                    </div>
                    <!-- Bilde -->
                    <div v-epi-edit="'Image'" v-if="model.image || inEditMode">
                        <img :src="`${model.image}?width=1152`"
                             :alt="model.imageDescription"
                             class="w-full" />
                    </div>
                    <!-- Bildebeskrivelse -->
                    <div v-epi-edit="'ImageDescription'" v-if="model.image || inEditMode">
                        <p v-if="model.imageDescription"
                           v-text="model.imageDescription"
                           class="text-xs"></p>
                    </div>
                    <!-- MainBody -->
                    <div v-if="model.mainBody || inEditMode" v-epi-edit="'MainBody'" id="main-body" class="main-body my-4 article pt-4" v-html="model.mainBody" />
                    <!-- Hva? -->
                    <div v-if="model.whatHeading || inEditMode" v-epi-edit="'WhatHeading'" >
                        <span class="mt-6 mb-2 font-semibold">{{model.whatHeading}}</span>
                    </div>
                    <div v-if="model.what || inEditMode" v-epi-edit="'What'" id="what" class="main-body my-4 article pt-4" v-html="model.what" />
                    <!-- Hvorfor? -->
                    <div v-if="model.whyHeading || inEditMode" v-epi-edit="'WhyHeading'">
                        <span  class="mt-6 mb-2 font-semibold">{{model.whyHeading}}</span>
                    </div>
                    <div v-if="model.why || inEditMode" v-epi-edit="'Why'" id="why" class="main-body my-4 article pt-4" v-html="model.why" />
                    <!-- Hvordan? -->
                    <div v-if="model.howHeading || inEditMode" v-epi-edit="'HowHeading'">
                        <span  class="mt-6 mb-2 font-semibold">{{model.howHeading}}</span>
                    </div>
                    <div v-if="model.how || inEditMode" v-epi-edit="'How'" id="what" class="main-body my-4 article pt-4" v-html="model.how" />
                    <!-- Links -->
                    <div class="flex flex-col">
                        <div v-if="model.childPageLinks.length > 0" class="md:flex mt-2">
                            <div class="md:flex flex-col">
                                <ul>
                                    <li v-for="link in model.childPageLinks"
                                        :key="link.url"
                                        class="py-2">
                                        <span class="text-blue-500 text-sm pr-2">
                                            <font-awesome-icon icon="circle" />
                                        </span>
                                        <span>
                                            <a :href="link.url"
                                               :title="link.name"
                                               class="text-blue-500 underline hover:text-red-500">
                                                {{ link.name }}
                                            </a>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- LastChanged-->
                    <div class="mt-4 flex">
                        <span v-if="model.published" class="py-2 text-sm text-grey-300">Publisert: {{model.published}},&nbsp;</span>
                        <span v-if="model.lastChanged" class="py-2 text-sm text-grey-300">endret: {{model.lastChanged}}</span>
                    </div>
                </div>
                <aside class="w-full lg:w-1/4 pr-0 lg:pl-4 mb-4">
                    <div class="py-5 mt-1.5 ">
                        <!-- Høyrebilde -->
                        <div v-epi-edit="'ImageRight'" v-if="model.imageRight || inEditMode">
                            <img :src="model.imageRight"
                                 class="w-full" />
                        </div>
                        <!-- Dokumenter -->
                        <div v-if="model.resourceDocuments || inEditMode" class="mt-4">
                            <span v-epi-edit="'ResourceDocumentsHeading'" class="font-semibold mb-2 mt-4">
                                {{model.resourceDocumentsHeading}}
                            </span>
                            <div class="bg-gray-300 h-px my-2"></div>
                            <ul v-epi-edit="'ResourceDocuments'">
                                <li v-if="model.resourceDocuments.length > 0" v-for="item in model.resourceDocuments" :key="item.id" class="pt-2">
                                    <div class="text-grey-500 hover:text-red-500">
                                        <a :href="item.url" :target="item.target" :title="item.text">
                                            <div class="flex">
                                                <span class="mr-2">
                                                    <font-awesome-icon style="margin-top: 6px;" icon="file-alt" />
                                                </span>
                                                <div class="flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2">
                                                    {{item.text}}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- Nettsteder -->
                        <div v-if="model.resourcePages || inEditMode" class="mt-4">
                            <span v-epi-edit="'ResourcePagesHeading'" class="font-semibold mb-2 mt-4">
                                {{model.resourcePagesHeading}}
                            </span>
                            <div class="bg-gray-300 h-px my-2"></div>
                            <ul v-epi-edit="'ResourcePages'">
                                <li v-if="model.resourcePages.length > 0" v-for="item in model.resourcePages" :key="item.id" class="pt-2">
                                    <div class="text-grey-500 hover:text-red-500">
                                        <a :href="item.url" :target="item.target" :title="item.text">
                                            <div class="flex flex-row">
                                                <div class="flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2">
                                                    {{item.text}}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import { mapState } from 'vuex';
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
    },
    computed: mapState({
        inEditMode: state => state.epiContext.inEditMode
    }),
    methods: {
        open(url) {
            console.log(url);
            window.open(url, '_blank');
        }
    },
};
</script>
