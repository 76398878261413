<template>
    <DefaultTemplate :model="model" class="bg-gray-100">
        <div class="container pb-4">
            <div class="">
                <Breadcrumbs :breadcrumbs="model.breadcrumbs" className="text-grey-400" />
            </div>
            <div class="block lg:flex">
                <div class="w-full lg:w-3/4 pr-0 lg:pr-4">
                    <!-- Overskrift -->
                    <div class="py-5 mt-1.5 ">
                        <h1>{{model.name}}</h1>
                    </div>
                    <!-- Hovedinnhold -->
                    <div v-if="model.mainBody || isEditable" v-epi-edit="'MainBody'" id="main-body" class="main-body my-4 article" v-html="model.mainBody" />
                    <!-- Aktuelle saker-->
                    <div v-if="dataLoaded">
                        <ul v-if="items.length > 0">
                            <li v-for="(item, index) in items"
                                :key="$uuid.v4(index)"
                                class="flex mb-4 border-t-6 bg-white relative">
                                <a :href="item.Url"
                                   class="absolute top-0 left-0 h-full w-full"
                                   title="Les mer">
                                    <span class="sr-only">
                                        Les mer
                                    </span>
                                </a>
                                <div class="w-1/4 py-4 md:py-0">
                                    <img :src="`${item.ImageUrl}?${getImageParams()}`"
                                         :alt="item.Name" />
                                </div>
                                <div class="w-3/4 p-4">
                                    <h3>{{ item.Name }}</h3>
                                    <div>
                                        <p class="py-2 text-sm text-grey-300">[Publisert {{item.PublishDate}}]</p>
                                        <p class="text-sm pb-2" :inner-html.prop="item.Extract" />
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div v-else>
                            <message-box title="Ingen treff" text="Ingen aktuelle artikler å vise" type="info" />
                        </div>
                        <Pagination v-if="totalPages > 1" :currentPage="currentPage" :totalPages="totalPages" @change="getItems" />
                    </div>
                    <div v-else class="my-12">
                        <LoadingSpinner size="2x" />
                    </div>
                </div>
                <aside class="w-full lg:w-1/4 pr-0 lg:pl-4 mb-4">
                    <div class="p-5 mb-6">
                        <dl class="text-sm">
                            <dt>
                                <ListFilter :allChildren="model.children" :currentChild="currentChild" @setFilterChild="setFilterChild" />
                            </dt>
                        </dl>
                    </div>
                </aside>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import { mapState } from 'vuex';
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import api from '@/Scripts/api/api';
import LoadingSpinner from '@/Scripts/components/atoms/LoadingSpinner/loadingSpinner.vue';
import Pagination from '@/Scripts/components/organisms/Pagination/pagination.vue';
import ListFilter from '@/Scripts/components/organisms/Filters/listFilter.vue';
import MessageBox from '../atoms/Messagebox/MessageBox.vue';

export default {
    props: ['model'],
    components: {
        api,
        Breadcrumbs,
        LoadingSpinner,
        Pagination,
        ListFilter,
        MessageBox,
    },
    data() {
        return {
            dataLoaded: false,
            error: false,
            items: [],
            currentPage: 1,
            totalPages: 0,
            numPrPage: 10,
            imageParams: 'scale=both&width=180&height=112',
            dateOptions: { year: 'numeric', month: '2-digit', day: '2-digit' },
            currentChild: null,
            filterOpen: false,
            mainImageParams: '?scale=both&width=1112&maxheight=500&'
        };
    },
    computed: mapState({
        isEditable: state => state.epiContext.isEditable
    }),
    created() {
        this.currentChild = this.model.children[0];
        this.getItems(this.currentPage);
    },
    methods: {
        setFilterChild(child) {
            this.filterOpen = false;
            this.currentChild = child;
            this.currentPage = 1;
            this.parentId = child.id;
            this.fetchAllDescendants = child.fetchAllDescendants;
            this.getItems(this.currentPage);
            sessionStorage.setItem('childId', child.id);
        },
        calculatePageCount(hitCount) {
            let count = hitCount / this.numPrPage;
            return Math.ceil(count > 0 ? count : 1);
        },
        getImageParams() {
            return 'scale=both&width=278&height=140&mode=crop&anchor=middelcenter';
        },
        getItems(page, click = false) {
            this.dataLoaded = false;
            const parameters = {
                size: this.numPrPage,
                page: page,
                parentId: this.currentChild.id,
                fetchAllDescendants: this.currentChild.fetchAllDescendants
            };
            api.getPagesByParent(parameters).then(response => {
                if (response.status === 200) {
                    console.log(response);
                    this.dataLoaded = true;
                    this.items = response.data.SearchResult;
                    this.totalPages = this.calculatePageCount(response.data.NumHitsPages);
                    this.currentPage = page;
                    if (click) {
                        document.getElementById('pagetop').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                    }
                } else {
                    this.dataLoaded = false;
                    this.items = [];
                    this.error = true;
                }
            });
        },
        toggleMobileFilterOpen() {
            this.filterOpen = !this.filterOpen;
        },
    },
};
</script>
