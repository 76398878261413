<template>
    <li class="border-b border-tundora-300 text-tundora-100  text-sm">
        <div v-if="item.children.length === 0" class="flex flex-col w-full">
            <div class="flex hover:bg-tundora-400">
                <div class="w-1/12" />
                <a class="block py-4 px-2 flex w-11/12" :href="item.url">
                    {{ item.text }}
                </a>
            </div>
        </div>

        <div v-if="item.children.length > 0" class="flex flex-col w-full">
            <div class="flex w-full  hover:bg-tundora-400">
                <div class="py-4 px-2 cursor-pointer w-1/12">
                    <a href="#"
                       @click.prevent="showChildren = !showChildren">
                        <font-awesome-icon v-if="!showChildren" class="fa-fw" icon="plus" />
                        <font-awesome-icon v-if="showChildren" class="fa-fw" icon="minus" />
                    </a>
                </div>
                <a class="block py-4 px-2 flex w-11/12" :href="item.url">
                    {{ item.text }}
                </a>
            </div>
            <div v-if="showChildren" class="flex flex-col w-full">
                <Item v-for="child in item.children" :item="child" :key="child.url" />
            </div>
        </div>
    </li>
</template>

<script>

export default {
    name: 'Item',
    props: ['item'],
    data() {
        return {
            showChildren: false,
        };
    },
};
</script>

