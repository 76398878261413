<template>
    <div class="t-default" :class="'c' + model.contentType[1] | PascalToKebab">
        <Header :model="model" />
        <slot :model="model"></slot>
        <Footer :model="model.footer" />
    </div>
</template>

<script>
import Header from '../organisms/Header/header.vue';
import Footer from '../organisms/Footer/footer.vue';
import { getSiteId, getPageId } from '@/Scripts/utilityFunctions.js';

export default {
    components: {
        Header,
        Footer,
    },
    props: ['model'],
    filters: {
        PascalToKebab: function (value) {
            return value.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
        }
    },
    created() {
        this.$store.dispatch('setSiteId', getSiteId(this.model));
        this.$store.dispatch('setPageId', getPageId(this.model));
    },
};
</script>
