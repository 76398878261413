export const getSiteId = model => {
    if (model && model.sites) {
        const site = model.sites.find(site => site.current === true);
        if (site && site.id) {
            return site.id;
        }
    }
    return '';
};

export const getPageId = model => {
    if (model && model.contentLink) {
        if (model.contentLink.id) {
            return model.contentLink.id;
        }
    }
    return '';
};
