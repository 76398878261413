var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "t-default",
      class: _vm._f("PascalToKebab")("c" + _vm.model.contentType[1]),
    },
    [
      _c("Header", { attrs: { model: _vm.model } }),
      _vm._v(" "),
      _vm._t("default", null, { model: _vm.model }),
      _vm._v(" "),
      _c("Footer", { attrs: { model: _vm.model.footer } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }