var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rounded p-4", class: _vm.style.bg }, [
    _c("div", { staticClass: "flex" }, [
      _vm.type
        ? _c("div", {
            staticClass: "flex-shrink-0",
            domProps: { innerHTML: _vm._s(_vm.icon) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "ml-3" }, [
        _vm.title
          ? _c(
              "h3",
              { staticClass: "text-lg font-bold", class: _vm.style.text },
              [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "mt-2", class: _vm.style.text }, [
          _vm.text
            ? _c("p", { domProps: { innerHTML: _vm._s(_vm.text) } })
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }