var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultTemplate",
    { staticClass: "bg-gray-100", attrs: { model: _vm.model } },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("Breadcrumbs", {
            attrs: {
              breadcrumbs: _vm.model.breadcrumbs,
              className: "text-grey-400",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "block lg:flex" }, [
            _c("div", { staticClass: "w-full lg:w-3/4 pr-0 lg:pr-8" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "Name",
                      expression: "'Name'",
                    },
                  ],
                  staticClass: "py-5 mt-1.5",
                },
                [_c("h1", [_vm._v(_vm._s(_vm.model.heading))])]
              ),
              _vm._v(" "),
              _vm.model.image || _vm.inEditMode
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Image",
                          expression: "'Image'",
                        },
                      ],
                    },
                    [
                      _c("img", {
                        staticClass: "w-full",
                        attrs: {
                          src: `${_vm.model.image}?width=1152`,
                          alt: _vm.model.imageDescription,
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.image || _vm.inEditMode
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "ImageDescription",
                          expression: "'ImageDescription'",
                        },
                      ],
                    },
                    [
                      _vm.model.imageDescription
                        ? _c("p", {
                            staticClass: "text-xs",
                            domProps: {
                              textContent: _vm._s(_vm.model.imageDescription),
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.mainBody || _vm.inEditMode
                ? _c("div", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "MainBody",
                        expression: "'MainBody'",
                      },
                    ],
                    staticClass: "main-body my-4 article pt-4",
                    attrs: { id: "main-body" },
                    domProps: { innerHTML: _vm._s(_vm.model.mainBody) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.model.whatHeading || _vm.inEditMode
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "WhatHeading",
                          expression: "'WhatHeading'",
                        },
                      ],
                    },
                    [
                      _c("span", { staticClass: "mt-6 mb-2 font-semibold" }, [
                        _vm._v(_vm._s(_vm.model.whatHeading)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.what || _vm.inEditMode
                ? _c("div", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "What",
                        expression: "'What'",
                      },
                    ],
                    staticClass: "main-body my-4 article pt-4",
                    attrs: { id: "what" },
                    domProps: { innerHTML: _vm._s(_vm.model.what) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.model.whyHeading || _vm.inEditMode
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "WhyHeading",
                          expression: "'WhyHeading'",
                        },
                      ],
                    },
                    [
                      _c("span", { staticClass: "mt-6 mb-2 font-semibold" }, [
                        _vm._v(_vm._s(_vm.model.whyHeading)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.why || _vm.inEditMode
                ? _c("div", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Why",
                        expression: "'Why'",
                      },
                    ],
                    staticClass: "main-body my-4 article pt-4",
                    attrs: { id: "why" },
                    domProps: { innerHTML: _vm._s(_vm.model.why) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.model.howHeading || _vm.inEditMode
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "HowHeading",
                          expression: "'HowHeading'",
                        },
                      ],
                    },
                    [
                      _c("span", { staticClass: "mt-6 mb-2 font-semibold" }, [
                        _vm._v(_vm._s(_vm.model.howHeading)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.how || _vm.inEditMode
                ? _c("div", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "How",
                        expression: "'How'",
                      },
                    ],
                    staticClass: "main-body my-4 article pt-4",
                    attrs: { id: "what" },
                    domProps: { innerHTML: _vm._s(_vm.model.how) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-col" }, [
                _vm.model.childPageLinks.length > 0
                  ? _c("div", { staticClass: "md:flex mt-2" }, [
                      _c("div", { staticClass: "md:flex flex-col" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.model.childPageLinks, function (link) {
                            return _c(
                              "li",
                              { key: link.url, staticClass: "py-2" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-blue-500 text-sm pr-2" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "circle" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-blue-500 underline hover:text-red-500",
                                      attrs: {
                                        href: link.url,
                                        title: link.name,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(link.name) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-4 flex" }, [
                _vm.model.published
                  ? _c("span", { staticClass: "py-2 text-sm text-grey-300" }, [
                      _vm._v(
                        "Publisert: " + _vm._s(_vm.model.published) + ", "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.lastChanged
                  ? _c("span", { staticClass: "py-2 text-sm text-grey-300" }, [
                      _vm._v("endret: " + _vm._s(_vm.model.lastChanged)),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("aside", { staticClass: "w-full lg:w-1/4 pr-0 lg:pl-4 mb-4" }, [
              _c("div", { staticClass: "py-5 mt-1.5" }, [
                _vm.model.imageRight || _vm.inEditMode
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "ImageRight",
                            expression: "'ImageRight'",
                          },
                        ],
                      },
                      [
                        _c("img", {
                          staticClass: "w-full",
                          attrs: { src: _vm.model.imageRight },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.resourceDocuments || _vm.inEditMode
                  ? _c("div", { staticClass: "mt-4" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "ResourceDocumentsHeading",
                              expression: "'ResourceDocumentsHeading'",
                            },
                          ],
                          staticClass: "font-semibold mb-2 mt-4",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.model.resourceDocumentsHeading) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "bg-gray-300 h-px my-2" }),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "ResourceDocuments",
                              expression: "'ResourceDocuments'",
                            },
                          ],
                        },
                        _vm._l(_vm.model.resourceDocuments, function (item) {
                          return _vm.model.resourceDocuments.length > 0
                            ? _c("li", { key: item.id, staticClass: "pt-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-grey-500 hover:text-red-500",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.url,
                                          target: item.target,
                                          title: item.text,
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "flex" }, [
                                          _c(
                                            "span",
                                            { staticClass: "mr-2" },
                                            [
                                              _c("font-awesome-icon", {
                                                staticStyle: {
                                                  "margin-top": "6px",
                                                },
                                                attrs: { icon: "file-alt" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(item.text) +
                                                  "\n                                            "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e()
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.resourcePages || _vm.inEditMode
                  ? _c("div", { staticClass: "mt-4" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "ResourcePagesHeading",
                              expression: "'ResourcePagesHeading'",
                            },
                          ],
                          staticClass: "font-semibold mb-2 mt-4",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.model.resourcePagesHeading) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "bg-gray-300 h-px my-2" }),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "ResourcePages",
                              expression: "'ResourcePages'",
                            },
                          ],
                        },
                        _vm._l(_vm.model.resourcePages, function (item) {
                          return _vm.model.resourcePages.length > 0
                            ? _c("li", { key: item.id, staticClass: "pt-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-grey-500 hover:text-red-500",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.url,
                                          target: item.target,
                                          title: item.text,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex flex-row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(item.text) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e()
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }