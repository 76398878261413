<template>
    <DefaultTemplate :model="model">
        <div class="container">
            <Breadcrumbs :breadcrumbs="model.breadcrumbs" className="text-grey-400" />
            <div class="block lg:flex">
                <div class="w-full lg:w-3/4 pr-0 lg:pr-8">
                    <div class="py-5 mt-1.5 ">
                        <h1>{{model.name}}</h1>
                    </div>
                    <div class="flex flex-wrap">
                        <div v-epi-edit="'MagazineImage'" class="">
                            <img v-if="model.magazineImage"
                                 :src="`${model.magazineImage}?scale=both&width=310&height=436&mode=crop&anchor=topleft`"
                                 class="main-body my-4 article pt-4 mr-8" />
                        </div>
                        <div class="">
                            <div v-if="model.mainBody" v-epi-edit="'MainBody'" id="main-body" class="main-body my-4 article pt-4" v-html="model.mainBody" />
                        </div>
                    </div>
                    <div class="mt-4">
                        <span>Endret dato: {{model.lastChanged}}</span>
                    </div>
                </div>
                <aside class="w-full lg:w-1/4 pr-0 lg:pl-4 mb-4">
                    <div class="py-5 mt-1.5 ">
                        <div v-if="model.documents" class="mt-4">
                            <span class="font-semibold mb-2 mt-4">Dokumenter</span>
                            <div v-if="model.documents.length > 0">
                                <dl v-epi-edit="'Documents'">
                                    <dd v-for="item in model.documents" :key="item.title" class="pt-2">
                                        <div class="text-grey-500 hover:text-red-500">
                                            <a :href="item.url" :target="item.target" :title="item.text">
                                                <div class="flex">
                                                    <span class="mr-2">
                                                        <font-awesome-icon style="margin-top: 6px;" icon="file-alt" />
                                                    </span>
                                                    <div class="flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2">
                                                        {{item.text}}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div v-if="model.publications" class="mt-4">
                            <span class="font-semibold mb-2 mt-4">Publikasjoner</span>
                            <div v-if="model.publications.length > 0">
                                <dl v-epi-edit="'Publications'">
                                    <dd v-for="item in model.publications" :key="item.title" class="pt-2">
                                        <div class="text-grey-500 hover:text-red-500">
                                            <a :href="item.url" :target="item.target" :title="item.text">
                                                <div class="flex flex-row">
                                                    <div class="flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2">
                                                        {{item.text}}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
    },
    methods: {
        open(url) {
            console.log(url);
            window.open(url, '_blank');
        }
    },
};
</script>
