<template>
    <header
        class="bg-white print:hidden"
    >
        <div class="c-top-menu__container bg-tundora-600">
            <div class="container">
                <div class="c-top-menu py-2 flex md:flex-wrap md:content-center">
                    <div class="w-1/4">
                        <Menu :menu="model.menu" />
                    </div>
                    <div class="w-3/4 flex justify-end">
                        <Topmenu :model="model.header"/>
                        <button
                            @click.prevent="showMobileSearch = !showMobileSearch"
                            class="md:hidden inline-block text-white opacity-75 hover:opacity-100 mr-3 cursor-pointer"
                        >
                            <font-awesome-icon icon="search" />
                        </button>
                        <Search :searchPage="model.searchPage" :className="`w-52`" class="hidden md:flex"/>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="showMobileSearch"
            class="flex justify-end w-full py-2 md:hidden bg-tundora-600"
        >
            <Search
                :searchPage="model.searchPage"
                :className="`w-full mr-4`"
                :defaultFocus="true"
            />
        </div>
        <div class="container">
            <div class="c-header py-5">
                <div class="flex flex-row justify-between">
                    <div class="flex mt-6">
                        <a href="/">
                            <img :v-if="model.header.mainLogoUrl" :src="model.header.mainLogoUrl"  />
                        </a>
                    </div>
                    <div class="flex items-center">
                        <a href="/" class="flex justify-end">
                            <img :v-if="model.header.mainImageUrl" :src="model.header.mainImageUrl" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Menu from '@/Scripts/components/organisms/Menu/menu.vue';
import Topmenu from './topmenu.vue';
import Search from './search.vue';

export default {
    props: ['model'],
    components: {
        Menu,
        Topmenu,
        Search
    },
    data() {
        return {
            showMobileSearch: false
        };
    },
    methods: {
        log(msg) {
            console.log(msg);
        },
        isCategoryPage() {
            return this.model.contentType.includes('KategoriPage');
        }
    }
};
</script>
