<template>
    <nav>
        <div class='flex'>
            <button
                v-for='(tab, index) in tabs' :key='tab.title'
                @click.prevent="selectTab(index)"
                class="py-4 px-6 border hover:text-gray-800 focus:outline-none"
                :class="[index === selectedIndex ? 'bg-white text-gray-800 border-white font-bold' : 'bg-gray-100 text-gray-500 border-gray-200']"
            >
                <div class="hidden lg:block hover:text-red-500">
                    {{tab.title}}
                </div>
                <div class="block lg:hidden hover:text-red-500">
                    <font-awesome-icon :icon="tab.icon" />
                </div>
            </button>
        </div>
        <slot></slot>
     </nav>
</template>

<script>

export default {
    props: {
        selectedTabIndex: {
            type: String,
            required: true,
        },
        storageKey: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            selectedIndex: parseInt(this.selectedTabIndex),
            tabs: []
        };
    },
    created() {
        this.tabs = this.$children;
    },
    mounted() {
        this.selectTab(parseInt(this.selectedTabIndex));
    },
    methods: {
        selectTab(i) {
            if (this.storageKey !== undefined) {
                localStorage.setItem(this.storageKey, i);
            }
            this.selectedIndex = i;
            this.$router.push({ query: { tab: i } });
            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i);
            });
        }
    },
};
</script>
