var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultTemplate",
    { attrs: { model: _vm.model } },
    [
      _vm.inEditMode
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _c("EpiProperty", {
                attrs: { propertyName: "Header", displayText: "Header" },
              }),
              _vm._v(" "),
              _c("EpiProperty", {
                attrs: { propertyName: "Footer", displayText: "Footer" },
              }),
              _vm._v(" "),
              _c("EpiProperty", {
                attrs: {
                  propertyName: "SearchPageRefernce",
                  displayText: "Søkeside",
                },
              }),
              _vm._v(" "),
              _c("EpiProperty", {
                attrs: {
                  propertyName: "LatestMagazine",
                  displayText: "Siste magasin",
                },
              }),
              _vm._v(" "),
              _c("EpiProperty", {
                attrs: {
                  propertyName: "CourseListRoot",
                  displayText: "Kursliste",
                },
              }),
              _vm._v(" "),
              _c("EpiProperty", {
                attrs: {
                  propertyName: "ArticleRoot",
                  displayText: "Artikkelliste",
                },
              }),
              _vm._v(" "),
              _c("EpiProperty", {
                attrs: {
                  propertyName: "MagazineRoot",
                  displayText: "Magasinliste",
                },
              }),
              _vm._v(" "),
              _c("EpiProperty", {
                attrs: {
                  propertyName: "RequestCourseRoot",
                  displayText: "Tilbud på forespørsel-liste",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container mb-12" },
        [
          _vm.model.highlightedArticle
            ? _c("HighlightedArticle", {
                attrs: {
                  article: _vm.model.highlightedArticle,
                  url: _vm.model.highlightedArticleUrl,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("ContentArea", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "MainContent",
                expression: "'MainContent'",
              },
            ],
            attrs: { model: _vm.model.mainContent },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("TabImage", { attrs: { color: "lightblue", location: "left" } }),
      _vm._v(" "),
      _c("div", { staticClass: "bg-blue-100" }, [
        _c("div", { staticClass: "container pt-12 pb-12 text-xl" }, [
          _vm.model.visionText
            ? _c("div", {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "VisionText",
                    expression: "'VisionText'",
                  },
                ],
                staticClass: "main-body my-4 article pt-4",
                attrs: { id: "vision-text" },
                domProps: { innerHTML: _vm._s(_vm.model.visionText) },
              })
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("TabImage", { attrs: { color: "darkblue", location: "right" } }),
      _vm._v(" "),
      _c("div", { staticClass: "bg-blue-500" }, [
        _c(
          "div",
          {
            staticStyle: {
              background:
                "url('/assets/images/nord/group.png') repeat-x 0px 190px",
            },
          },
          [
            _c(
              "div",
              { staticClass: "container pt-12" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-3xl text-white text-center main-body my-2 article pt-4 pb-1",
                  },
                  [_vm._v("Kurs og konferanser")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "text-right w-full right" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "text-base cursor-pointer underline text-blue-300 hover:text-red-300",
                        attrs: { href: _vm.model.courseListUrl },
                      },
                      [_vm._v("Se alle kurs og konferanser")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "mx-4 text-white" }, [
                    _vm._v("//"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-left w-full left" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "text-base cursor-pointer underline text-blue-300 hover:text-red-300",
                        attrs: { href: _vm.model.courseDocumentationListUrl },
                      },
                      [_vm._v("Kursmateriell")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("CourseSlider", { attrs: { courses: _vm.model.courses } }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container pt-12 pb-20" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-3xl text-white text-center main-body my-2 article pt-4 pb-1",
                  },
                  [_vm._v("Tilbud på forespørsel")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "w-full text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "text-base cursor-pointer underline text-blue-300 hover:text-red-300",
                      attrs: { href: _vm.model.requestCourseListUrl },
                    },
                    [_vm._v("Se alle tilbud på forespørsel")]
                  ),
                ]),
                _vm._v(" "),
                _c("CourseOnDemandSlider", {
                  attrs: { courses: _vm.model.coursesOnRequest },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("TabImage", { attrs: { color: "white", location: "left" } }),
      _vm._v(" "),
      _c("div", { staticClass: "bg-white" }, [
        _c("div", { staticClass: "container pt-12 pb-12" }, [
          _c(
            "div",
            {
              staticClass:
                "sm:text-3xl text-base text-center main-body my-4 article pt-4 uppercase",
            },
            [
              _vm._v(
                "Våre satsninger rettet mot kommuner og spesialisthelsetjenesten"
              ),
            ]
          ),
          _vm._v(" "),
          _vm.inEditMode
            ? _c(
                "div",
                [
                  _c("EpiProperty", {
                    attrs: {
                      propertyName: "ThemePages",
                      displayText: "Temasider",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.themePages
            ? _c(
                "ul",
                { staticClass: "flex flex-wrap content-evenly md:mb-12 mb-6" },
                _vm._l(_vm.model.themePages, function (page) {
                  return _c(
                    "li",
                    { key: page.id, staticClass: "xl:w-1/3 md:w-1/2 w-full" },
                    [
                      _c(
                        "div",
                        { staticClass: "border-2 m-1 relative group h-full" },
                        [
                          _c("div", { staticClass: "p-6" }, [
                            _c(
                              "a",
                              { attrs: { href: page.url, target: "_self" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-xl font-semibold cursor-pointer hover:text-red-500 hover:underline",
                                  },
                                  [_vm._v(_vm._s(page.heading))]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "py-2" }, [
                            _c("img", {
                              staticClass: "object-fill w-full",
                              attrs: {
                                "v-if": _vm.lineImage,
                                src: _vm.lineImage,
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          page.links
                            ? _c(
                                "div",
                                { staticClass: "p-6" },
                                _vm._l(page.links, function (link) {
                                  return _c("div", { key: link.id }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: link.url,
                                          target: "_self",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex mb-2" },
                                          [
                                            _c("img", {
                                              staticClass: "mr-2 h-4 w-4",
                                              attrs: {
                                                "v-if": _vm.bulletImage,
                                                src: _vm.bulletImage,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-base cursor-pointer hover:text-red-500 hover:underline",
                                              },
                                              [_vm._v(_vm._s(link.name))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("TabImage", { attrs: { color: "lightblue", location: "right" } }),
      _vm._v(" "),
      _c("div", { staticClass: "bg-blue-100" }, [
        _c("div", { staticClass: "container pt-12 pb-12" }, [
          _c(
            "div",
            { staticClass: "text-3xl text-center main-body my-4 article pt-4" },
            [_vm._v("Magasinet SPOR")]
          ),
          _vm._v(" "),
          _vm.inEditMode
            ? _c(
                "div",
                [
                  _c("EpiProperty", {
                    attrs: {
                      propertyName: "MagazineContent",
                      displayText: "Magasinsider",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "w-full text-center mb-8 mt-2" }, [
            _c(
              "a",
              {
                staticClass:
                  "text-base cursor-pointer underline text-blue-600 hover:text-red-500",
                attrs: { href: _vm.model.magazineListUrl },
              },
              [_vm._v("Se alle publikasjoner")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass:
                "flex flex-wrap justify-center text-left content-evenly mb-10",
            },
            _vm._l(_vm.model.magazines, function (magazine) {
              return _c("li", { key: magazine.id, staticClass: "p-8" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "shadow-md hover:shadow-sm rounded relative group h-full",
                  },
                  [
                    _c("a", { attrs: { href: magazine.url } }, [
                      _c("img", {
                        attrs: {
                          src: `${magazine.image}?scale=both&width=220&height=310&mode=crop&anchor=middelcenter`,
                        },
                      }),
                    ]),
                  ]
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("TabImage", { attrs: { color: "white", location: "left" } }),
      _vm._v(" "),
      _c("div", { staticClass: "bg-white" }, [
        _c("div", { staticClass: "container pt-12 pb-12" }, [
          _c("div", { staticClass: "main-body my-4 article pt-4" }, [
            _c("div", { staticClass: "flex flex-row flex-wrap" }, [
              _c(
                "div",
                { staticClass: "lg:w-1/2 xl:pl-40 xl:pr-40 pl-10 pr-10" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.forebygging.no",
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "hover:shadow-md",
                        attrs: {
                          src: "/Assets/Images/Nord/forebygging-logo.jpg",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-base text-center pl-10 pr-10 md:pl-2 md:pr-2",
                    },
                    [
                      _vm._v(
                        "\n                            er en kunnskapsbase for rusforebyggende og helsefremmende arbeid\n                        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "lg:w-1/2 xl:pl-40 xl:pr-40 pl-10 pr-10" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.kommunetorget.no",
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "hover:shadow-md",
                        attrs: {
                          src: "/Assets/Images/Nord/kommunetorget-logo.jpg",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }