<template>
    <form
        method="get"
        :action="searchPageUrl"
        class="w-2/3 md:w-auto relative flex ml-4 items-center"
    >
        <button
            class="absolute opacity-75 pl-2" :class="[hasFocus ? 'text-black' : 'text-white']"
            type="submit"
            @focus="toggleFocus(true)"
            @blur="toggleFocus(false)"
        >
            <font-awesome-icon icon="search" />
        </button>
        <input
            type="search"
            name="query"
            autocomplete="off"
            class="rounded-full pl-7 pr-3 py-1 text-sm color-black placeholder-opacity-25 focus:outline-none focus:bg-white text-white focus:text-black"
            :class="[hasFocus ? 'bg-white placeholder-black ' + className : 'bg-search placeholder-white ' + className]"
            v-model="query"
            placeholder="Søk på tittel, forfatter, tema o.l."
            @focus="toggleFocus(true)"
            @blur="toggleFocus(false)"
        />
    </form>
</template>

<script>
export default {
    props: {
        searchPage: {
            type: Object
        },
        className: {
            type: String,
            'default': ''
        },
        defaultFocus: {
            type: Boolean,
            'default': false
        }
    },
    data() {
        let q = '';
        if (this.$route.query && this.$route.query.query) {
            q = this.$route.query.query;
        }

        return {
            query: q,
            hasFocus: this.defaultFocus,
            searchPageUrl: (this.searchPage && this.searchPage.value ? this.searchPage.value.url : '/sok/'),
            mobiletoggle: false
        };
    },
    methods: {
        toggleFocus(state) {
            this.hasFocus = state;
        }
    }
};
</script>
