/**
 * Registers all of the content components representing pages and blocks. They
 * need to be globally registered because they are dynamically rendered by the
 * component selectors (`PageComponentSelector` and `BlockComponentSelector`).
 */
import Vue from 'vue';

import router from '@/Scripts/router';
import store from '@/Scripts/store';
import { sync } from 'vuex-router-sync';
sync(store, router);

// `epiMessages` does not export anything but registers the `beta/contentSaved`
// and `beta/epiReady` message handlers that updates the vuex store.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#Import_a_module_for_its_side_effects_only
import '@/Scripts/epiMessages';

// generate svg sprite from all files in /Assets/Images/SVG
//const files = require.context('@/Images/svg-sprites', true, /.*\.svg$/);
//files.keys().forEach(files);

// Episerver helpers
import EpiEdit from '@/Scripts/directives/epiEdit';
Vue.directive('epi-edit', EpiEdit);

// Tailwind
// import 'tailwindcss/tailwind.css';
import '@/Styles/main.css';

// Page Templates
import DefaultTemplate from '@/Scripts/components/templates/DefaultTemplate.vue';

// Pages
import NordStartPage from '@/Scripts/components/pages/NordStartPage.vue';
import NordArticlePage from '@/Scripts/components/pages/NordArticlePage.vue';
import NordCoursePage from '@/Scripts/components/pages/NordCoursePage.vue';
import NordCourseListPage from '@/Scripts/components/pages/NordCourseListPage.vue';
import NordMagazinePage from '@/Scripts/components/pages/NordMagazinePage.vue';
import NordContainerPage from '@/Scripts/components/pages/NordContainerPage.vue';
import NordSearchPage from '@/Scripts/components/pages/NordSearchPage.vue';
import NordThemePage from '@/Scripts/components/pages/NordThemePage.vue';

// Blocks
import GenericBlock from '@/Scripts/components/blocks/GenericBlock.vue';
import SliderBlock from '@/Scripts/components/blocks/SliderBlock/SliderBlock.vue';
import NordLatestPublicationsBlock from '@/Scripts/components/blocks/NordLatestPublicationsBlock.vue';

// Views
import Preview from '@/Scripts/components/Preview.vue';
import DefaultPage from '@/Scripts/components/DefaultPage.vue';

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas, fab);

// Tippy (tooltip)
import VueTippy, { TippyComponent } from 'vue-tippy';
Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

// Scroll to https://vue-scrollto.netlify.app/
// var VueScrollTo = require('vue-scrollto');
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

// UUID
import UUID from 'vue-uuid';
Vue.use(UUID);

// Templates
Vue.component('DefaultTemplate', DefaultTemplate);

// Pages
Vue.component('NordStartPage', NordStartPage);
Vue.component('NordArticlePage', NordArticlePage);
Vue.component('NordCoursePage', NordCoursePage);
Vue.component('NordCourseListPage', NordCourseListPage);
Vue.component('NordMagazinePage', NordMagazinePage);
Vue.component('NordContainerPage', NordContainerPage);
Vue.component('NordSearchPage', NordSearchPage);
Vue.component('NordThemePage', NordThemePage);

// Blocks
Vue.component('GenericBlock', GenericBlock);
Vue.component('SliderBlock', SliderBlock);
Vue.component('NordLatestPublicationsBlock', NordLatestPublicationsBlock);

// Views
Vue.component('Preview', Preview);
Vue.component('DefaultPage', DefaultPage);

// Other
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Filters
//import { truncate } from './vueFilters.js';
//Vue.filter('truncate', truncate);

/* eslint-disable-next-line no-unused-vars */
let App = new Vue({
    el: '#App',
    store,
    router
});
