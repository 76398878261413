var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.className,
        _vm.fluid
          ? "container-fluid"
          : _vm.full
          ? "container-full"
          : "container",
        _vm.background && "container--background-" + _vm.background,
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }