var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    [
      _c(
        "div",
        { staticClass: "flex" },
        _vm._l(_vm.tabs, function (tab, index) {
          return _c(
            "button",
            {
              key: tab.title,
              staticClass:
                "py-4 px-6 border hover:text-gray-800 focus:outline-none",
              class: [
                index === _vm.selectedIndex
                  ? "bg-white text-gray-800 border-white font-bold"
                  : "bg-gray-100 text-gray-500 border-gray-200",
              ],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.selectTab(index)
                },
              },
            },
            [
              _c("div", { staticClass: "hidden lg:block hover:text-red-500" }, [
                _vm._v(
                  "\n                " + _vm._s(tab.title) + "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block lg:hidden hover:text-red-500" },
                [_c("font-awesome-icon", { attrs: { icon: tab.icon } })],
                1
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }