<template>
    <div class="print:hidden">
        <footer class="footer bg-grey-500 mt-10 pb-2">
            <div class="container">
                <img :v-if="background" :src="background" />
            </div>
        </footer>
        <footer class="footer bg-grey-500 text-white text-sm p-4 md:p-8">
            <div class="container md:flex divide-y divide-grey-400 md:divide-y-0">
                <div class="w-full py-4 md:p-0 md:mb-8">
                    <div class="flex">
                        <a href="/">
                            <img :v-if="model.logoLeftUrl" :src="model.logoLeftUrl" />
                        </a>
                    </div>
                    <div class="flex">
                        <h3 class=" text-base mt-8 mr-20 leading-8">
                            {{model.logoLeftText}}
                        </h3>
                    </div>
                </div>
                <div class="w-full py-4 md:p-0 md:mb-8">
                    <contact :model="model" />
                </div>
                <div class="w-full py-4 md:p-0 md:mb-8">
                    <div v-if="model.thirdColumnLinks">
                        <h3 class="uppercase text-base mb-2">
                            {{model.thirdColumnHeader}}
                        </h3>
                        <ul>
                            <li v-for="link in model.thirdColumnLinks" :key="link.href">
                                <a :href="link.href" :target="link.target" :title="link.title" class="">
                                    <span class="underline text-white hover:text-red-500">
                                        {{link.text}}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="w-full py-4 md:p-0 md:mb-8">
                    <div class="flex">
                        <a href="/">
                            <img :v-if="model.logoRightUrl" :src="model.logoRightUrl" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import EpiLink from '@/Scripts/components/atoms/EpiLink/index.vue';
import Contact from '@/Scripts/components/organisms/Contact/contact.vue';

export default {
    props: ['model'],
    components: {
        EpiLink,
        Contact,
    },
    data() {
        return {
            background: '/Assets/Images/Nord/footer_back.png',
        };
    },
};
</script>
