<template>
    <DefaultTemplate :model="model">
        <div class="bg-gray-100 pb-4">
            <div class="container">
                <Breadcrumbs :breadcrumbs="model.breadcrumbs" className="text-grey-400" />
                <div v-if="inEditMode" class="container">
                    <EpiProperty propertyName="RegistrationButtonText" displayText="Knappetekst ved påmelding mulig" />
                </div>
                <div class="w-full">
                    <div class="bg-white p-5 mt-1.5 ">
                        <h1>{{model.name}}</h1>
                    </div>
                </div>
                <div class="w-full mt-4">
                    <Tabs class="bg-white" :selectedTabIndex="selectedTabIndex" :storageKey="storageKey">
                        <Tab title="Kurs og konferanser" icon="chalkboard">
                            <div v-if="regularCourses.length > 0" class="md:flex mt-2">
                                <div class="w-full">
                                    <div class="md:flex flex-col">
                                        <ol class="bg-white">
                                            <li v-for="result in regularCourses"
                                                :key="result.url"
                                                class="p-4 border-2 m-2 hover:shadow-md shadow-sm">
                                                <a :href="result.Url"
                                                   :title="result.Title"
                                                   class="cursor-pointer">
                                                    <div class="w-full lg:w-3/4">
                                                        <div class="bg-white py-5 my-1.5 flex h-full">
                                                            <div class="md:w-1/12 w-1/4 md:border-r-2 md:pr-4">
                                                                <div class="flex flex-col justify-around text-center h-full w-full">
                                                                    <div class="text-gray-600">{{ result.Year }}</div>
                                                                    <div class="text-4xl text-red-400">{{ result.Day }}</div>
                                                                    <div class="text-gray-600">{{ result.Month }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="md:w-11/12 w-3/4 md:pl-4 flex flex-col">
                                                                <h3 class="md:flex md:justify-between text-2xl mb-2 md:items-start font-semibold pt-2">
                                                                    {{ result.Title }}
                                                                </h3>
                                                                <div v-if="result.RegistrationDeadline" class="pt-2">
                                                                    Påmeldingsfrist: {{ result.RegistrationDeadline }}
                                                                </div>
                                                                <div v-if="result.Location" class="pt-2">
                                                                    <font-awesome-icon icon="map-marker" />
                                                                    {{ result.Location }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="m-4 pb-4">
                                <message-box title="Ingen kurs" text="Det finnes for øyeblikket ingen kurs og konferanser" type="info" />
                            </div>
                        </Tab>
                        <Tab title="Tilbud på forespørsel" icon="question">
                            <div v-if="onDemandCourses.length > 0" class="md:flex mt-2">
                                <div class="w-full">
                                    <div class="md:flex flex-col">
                                        <ol class="bg-white">
                                            <li v-for="result in onDemandCourses"
                                                :key="result.url"
                                                class="p-4 border-2 m-2 hover:shadow-md shadow-sm">
                                                <a :href="result.Url"
                                                   :title="result.Title"
                                                   class="cursor-pointer">
                                                    <div class="w-full lg:w-3/4">
                                                        <div class="bg-white py-5 my-1.5 flex h-full">
                                                            <div class="md:w-1/12 w-1/4 text-4xl text-red-400 md:border-r-2 md:pr-4">
                                                                <div class="flex flex-col justify-around h-full w-full">
                                                                    <font-awesome-icon icon="chalkboard-teacher" />
                                                                </div>
                                                            </div>
                                                            <div class="md:w-11/12 w-3/4 md:pl-4 flex flex-col">
                                                                <h3 class="md:flex md:justify-between text-base mb-2 md:items-start font-semibold pt-2">
                                                                    {{ result.Title }}
                                                                </h3>
                                                                <div v-if="result.Duration" class="pt-2">
                                                                    Varighet: {{ result.Duration }}
                                                                </div>
                                                                <div v-if="result.Location" class="pt-2">
                                                                    <font-awesome-icon icon="map-marker" />
                                                                    {{ result.Location }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="m-4 pb-4">
                                <message-box title="Ingen tilbud på forespørsel" text="Det finnes for øyeblikket ingen tilbud på forespørsel" type="info" />
                            </div>
                        </Tab>
                        <Tab title="Kursmateriell" icon="file">
                            <div v-if="passedCourses.length > 0" class="md:flex mt-2">
                                <div class="w-full">
                                    <div class="md:flex flex-col">
                                        <ol class="bg-white">
                                            <li v-for="result in passedCourses"
                                                :key="result.url"
                                                class="p-4 border-2 m-2 hover:shadow-md shadow-sm">
                                                <a :href="result.Url"
                                                   :title="result.Title"
                                                   class="cursor-pointer">
                                                    <div class="w-full lg:w-3/4">
                                                        <div class="bg-white py-5 my-1.5 flex h-full">
                                                            <div class="md:w-1/12 w-1/4 md:border-r-2 md:pr-4">
                                                                <div class="flex flex-col justify-around text-center h-full w-full">
                                                                    <div class="text-gray-600">{{ result.Year }}</div>
                                                                    <div class="text-4xl text-red-400">{{ result.Day }}</div>
                                                                    <div class="text-gray-600">{{ result.Month }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="md:w-11/12 w-3/4 md:pl-4 flex flex-col">
                                                                <h3 class="md:flex md:justify-between text-base mb-2 md:items-start font-semibold pt-2">
                                                                    {{ result.Title }}
                                                                </h3>
                                                                <div v-if="result.Location" class="pt-2">
                                                                    <font-awesome-icon icon="map-marker" />
                                                                    {{ result.Location }}
                                                                </div>
                                                                <div v-if="result.Documentation.length > 0" class="flex pt-2">
                                                                    <div class="md:w-1/3 w-full" v-for="item in result.Documentation" :key="item.href">
                                                                        <a :href="item.Url" :target="item.target" :title="item.text">
                                                                            <div class="flex flex-wrap">
                                                                                <div class="text-red-400 hover:text-gray-400">
                                                                                    <font-awesome-icon style="margin-top: 6px;" icon="file-pdf" />
                                                                                </div>
                                                                                <div class="text-red-400 hover:text-gray-400 underline truncate ml-2">
                                                                                    {{item.Text}}
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="m-4 pb-4">
                                <message-box title="Ingen kursdokumentasjon" text="Det finnes for øyeblikket ingen kursdokumentasjon tilgjengelig" type="info" />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/Scripts/api/api';
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import Tab from '@/Scripts/components/atoms/Tabs/Tab.vue';
import Tabs from '@/Scripts/components/atoms/Tabs/Tabs.vue';
import MessageBox from '../atoms/Messagebox/MessageBox.vue';
import EpiProperty from '@/Scripts/components/EpiProperty.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
        Tab,
        Tabs,
        MessageBox,
        EpiProperty,
    },
    data() {
        return {
            storageKey: 'listTabStorageKey',
            selectedTabIndex: this.getSelectedTabIndex(),
            dataLoaded: false,
            error: false,
            regularCourses: [],
            onDemandCourses: [],
            passedCourses: [],
        };
    },
    computed: mapState({
        inEditMode: state => state.epiContext.inEditMode
    }),
    created() {
        this.getRegularCourses();
        this.getOnDemandCourses();
        this.getPassedCourses();
    },
    methods: {
        getRegularCourses() {
            let site = this.model.sites.filter(site => {
                return site.current === true;
            });
            const body = {
                words: '',
                siteId: site[0].id,
                pageNumber: 1,
                size: 1000,
                onDemand: false,
                getPassedCourses: false,
            };
            api.postSearchCourses(body).then(response => {
                const data = response.data;
                if (!data.error) {
                    this.regularCourses = data.SearchCourseResult;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getOnDemandCourses() {
            let site = this.model.sites.filter(site => {
                return site.current === true;
            });
            const body = {
                words: '',
                siteId: site[0].id,
                pageNumber: 1,
                size: 1000,
                onDemand: true,
                getPassedCourses: false,
            };
            api.postSearchCourses(body).then(response => {
                const data = response.data;
                if (!data.error) {
                    this.onDemandCourses = data.SearchCourseResult;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getPassedCourses() {
            let site = this.model.sites.filter(site => {
                return site.current === true;
            });
            const body = {
                words: '',
                siteId: site[0].id,
                pageNumber: 1,
                size: 1000,
                onDemand: false,
                getPassedCourses: true,
            };
            api.postSearchCourses(body).then(response => {
                const data = response.data;
                if (!data.error) {
                    this.passedCourses = data.SearchCourseResult;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getSelectedTabIndex() {
            if (this.$route.query.tab === undefined) {
                let tabIndex = localStorage.getItem('listTabStorageKey');
                return tabIndex === null ? '0' : tabIndex;
            } else {
                return this.$route.query.tab;
            }
        }
    },
};
</script>
