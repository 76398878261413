<template>
    <div>
        <div>
            <swiper ref="slider" :options="swiperOptions">
                <swiper-slide class="flex flex-wrap p-12 items-center justify-center w-full" v-for="(group, gindex) in groups" :key="gindex">
                    <div v-for="(course, cindex) in group" :key="cindex" class="xl:w-1/5 lg:w-1/2 w-full h40">
                        <div class="mx-2 mt-2 bg-white px-10 pt-10 pb-8 h-60 hover:text-red-600">
                            <a :href="course.url" target="_self">
                                <div class="h-1/2 border-b-4 pb-2 flex">
                                    <span class="self-end font-semibold">{{course.name}}</span>
                                </div>
                                <div v-if="course.isCourseOnRequest" class="h-1/2 pt-2">
                                    <span class="inline-block align-top">{{course.duration}}</span>
                                </div>
                                <div v-else class="h-1/2 pt-2">
                                    <span class="inline-block align-top">{{course.date}}</span>
                                </div>
                            </a>
                        </div>
                        <div class="relative mx-2 mb-2 bg-white">
                            <div v-if="course.tag" class="absolute bottom-0 right-0 bg-orange-500 py-1 px-2 rounded-r-none rounded-t text-right uppercase">
                                {{course.tag}}
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <div v-if="groups.length > 1" id="courseSliderPrevButton" class="swiper-button-prev text-white mr-8" slot="button-prev"></div>
                <div v-if="groups.length > 1" id="courseSliderNextButton" class="swiper-button-next text-white ml-8" slot="button-next"></div>
            </swiper>
        </div>
        <div v-if="groups.length > 1"
             id="courseSliderPagination"
             class="relative pb-4 flex justify-center"
             slot="pagination" />
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
    props: {
        courses: {
            type: [],
            required: true,
        },
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            id: null,
            groups: this.groupCourses(this.courses, 5),
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesPerGroup: 1,
                loop: false,
                loopFillGroupWithBlank: true,
                pagination: {
                    el: '#courseSliderPagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '#courseSliderNextButton',
                    prevEl: '#courseSliderPrevButton',
                }
            }
        };
    },
    computed: {
        swiper() {
            return this.$refs.slider.$swiper;
        },
    },
    methods: {
        groupCourses(data, n) {
            var group = [];
            for (var i = 0, j = 0; i < data.length; i++) {
                if (i >= n && i % n === 0) {
                    j++;
                }
                group[j] = group[j] || [];
                group[j].push(data[i]);
            }
            return group;
        },
    },
};
</script>
