<template>
    <ul
        class="breadcrumbs flex flex-wrap px-4 md:px-0 pl-0 pt-3 md:py-3 print:hidden"
        :class="[className]"
    >
        <li v-for="(item, index) in items" :key="item.url" class="relative mr-6">
            <a :href="item.url" class="hover:underline">
                <span v-if="index === 0">Forside</span>
                <span v-else>{{ item.text }}</span>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        breadcrumbs: Array,
        skipLast: {
            type: Boolean,
            'default': false
        },
        className: String
    },
    data() {
        return {
            items: this.skipLast ? this.breadcrumbs.slice(0, -1) : this.breadcrumbs
        };
    }
};
</script>
