<template>
    <div
        class="c_main-menu relative"
        :class="[menuOpen ? 'c_main-menu--open' : '']"
        v-click-outside="closeMenu"
    >
        <button
            class="text-white mt-1"
            @click="toggleMenu"
        >
            <font-awesome-icon class="fa-fw" :icon="icon" /><span class="pl-1.5">MENY</span>
        </button>
        <div
            class="absolute z-50 mt-2 bg-tundora-600 w-80 linear transition-scale origin-top duration-200 overflow-hidden"
            :class="[menuOpen ? 'shadow-2xl menuOpened' : 'menuClosed']"
        >
            <ul class="list-none m-0 p-0">
                <Item v-for="item in menu" :item="item" :key="item.url"/>
            </ul>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Item from '@/scripts/components/organisms/Menu/menuitem.vue';

export default {
    props: ['menu'],
    data() {
        return {
            menuOpen: false,
            icon: 'bars',
            showChildren: false,
        };
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
            this.icon = this.menuOpen ? 'times' : 'bars';
        },
        closeMenu() {
            this.menuOpen = false;
            this.icon = 'bars';
        }
    },
    components: {
        Item
    },
    directives: {
        ClickOutside
    }
};
</script>

<style scoped>
    .menuOpened {
        transform: scaleY(1);
    }
    .menuClosed {
        transform: scaleY(0);
    }
</style>
