var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "border shadow-lg hover:shadow-md bg-white rounded-b-lg relative my-10 lg:mt-20 lg:mb-20",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "border-t-8 border-blue-500 p-8 lg:p-12 lg:flex lg:items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "mr-12 mb-6 lg:mb-0 text-center lg:text-left w-full lg:w-1/4",
            },
            [
              _c("img", {
                staticClass: "mx-auto",
                attrs: { src: _vm.imageUrl, alt: _vm.article.heading },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "w-full lg:w-3/4" }, [
            _c("h3", { staticClass: "uppercase text-gray-600 mb-4 text-sm" }, [
              _vm._v("\n                Aktuelt\n            "),
            ]),
            _vm._v(" "),
            _c("h2", {
              staticClass: "font-bold text-gray-600 text-2xl mb-2",
              domProps: { innerHTML: _vm._s(_vm.article.heading) },
            }),
            _vm._v(" "),
            _c("p", {
              staticClass: "leading-6",
              domProps: { innerHTML: _vm._s(_vm.article.extract) },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "absolute w-full h-full top-0 left-0 cursor-pointer",
          attrs: { href: _vm.article.url, title: _vm.article.heading },
        },
        [
          _c("span", { staticClass: "sr-only" }, [
            _vm._v("\n            Les mer\n        "),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }