var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "print:hidden" }, [
    _c("footer", { staticClass: "footer bg-grey-500 mt-10 pb-2" }, [
      _c("div", { staticClass: "container" }, [
        _c("img", { attrs: { "v-if": _vm.background, src: _vm.background } }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "footer",
      { staticClass: "footer bg-grey-500 text-white text-sm p-4 md:p-8" },
      [
        _c(
          "div",
          {
            staticClass:
              "container md:flex divide-y divide-grey-400 md:divide-y-0",
          },
          [
            _c("div", { staticClass: "w-full py-4 md:p-0 md:mb-8" }, [
              _c("div", { staticClass: "flex" }, [
                _c("a", { attrs: { href: "/" } }, [
                  _c("img", {
                    attrs: {
                      "v-if": _vm.model.logoLeftUrl,
                      src: _vm.model.logoLeftUrl,
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex" }, [
                _c("h3", { staticClass: "text-base mt-8 mr-20 leading-8" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.model.logoLeftText) +
                      "\n                    "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-full py-4 md:p-0 md:mb-8" },
              [_c("contact", { attrs: { model: _vm.model } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "w-full py-4 md:p-0 md:mb-8" }, [
              _vm.model.thirdColumnLinks
                ? _c("div", [
                    _c("h3", { staticClass: "uppercase text-base mb-2" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.model.thirdColumnHeader) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(_vm.model.thirdColumnLinks, function (link) {
                        return _c("li", { key: link.href }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: link.href,
                                target: link.target,
                                title: link.title,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "underline text-white hover:text-red-500",
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(link.text) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full py-4 md:p-0 md:mb-8" }, [
              _c("div", { staticClass: "flex" }, [
                _c("a", { attrs: { href: "/" } }, [
                  _c("img", {
                    attrs: {
                      "v-if": _vm.model.logoRightUrl,
                      src: _vm.model.logoRightUrl,
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }