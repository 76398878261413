var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isEditable
    ? _c(
        "button",
        {
          directives: [
            {
              name: "epi-edit",
              rawName: "v-epi-edit",
              value: _vm.propertyName,
              expression: "propertyName",
            },
          ],
          staticClass:
            "bg-blue-400 hover:bg-grey-700 text-white py-1 px-2 mb-2 mr-2",
          attrs: { type: "button" },
        },
        [
          _c(
            "span",
            [
              _c("font-awesome-icon", { attrs: { icon: "edit" } }),
              _vm._v(" " + _vm._s(_vm.buttontext) + "\n    "),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }