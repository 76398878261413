<template>
    <div v-if="model.articles">
        <div class="mx-0 mt-10 mb-2">
            <div class="bg-white lg:bg-transparent slider">
                <swiper ref="slider" :options="swiperOptions">
                    <swiper-slide class="flex items-center justify-center" v-for="article in model.articles" :key="article.id">
                        <div class="relative">
                            <EpiLink v-if="article.url" :url="article.url" :title="`Les mer om ${article.heading}`">
                                <img :src="`${article.image}?${getImageParams()}`" />
                                <div class="relative w-full lg:p-8 pt-4 text-black bg-white lg:absolute lg:bottom-0 lg:top-0 lg:right-20 lg:w-4/12 lg:bg-opacity-80">
                                    <div class="text-lg md:text-2xl lg:text-3xl">
                                        <div class="pb-4 text-lg border-b-2">
                                            <h2>{{article.name}}</h2>
                                        </div>
                                        <div v-html="article.ingress" class="h-32 py-4 text-base overflow-hidden"></div>
                                    </div>
                                    <span class="inline-block mt-4 md:btn-sm md:mt-6 underline text-blue-600 hover:text-red-600">
                                        {{ article.buttonIcon }}
                                        Les mer &rarr;
                                    </span>
                                </div>
                            </EpiLink>
                        </div>
                    </swiper-slide>
                    <div v-if="model.articles.length > 1" id="sliderPrevButton" class="hidden lg:block swiper-button-prev text-blue-500 mr-8" slot="button-prev"></div>
                    <div v-if="model.articles.length > 1" id="sliderNextButton" class="hidden lg:block swiper-button-next text-blue-500 ml-8" slot="button-next"></div>
                </swiper>
            </div>
            <div v-if="model.articles.length > 1"
                 id="sliderPagination"
                 class="relative pb-4 flex justify-center"
                 slot="pagination" />
            <div v-if="model.articlesRoot && model.articles.length > 0" class="flex justify-center mt-6">
                <a :href="model.articlesRoot.value.url">
                    <span class="text-base cursor-pointer underline text-blue-600 hover:text-red-500">Se alle saker</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import EpiLink from '@/Scripts/components/atoms/EpiLink/index.vue';

export default {
    props: ['model'],
    components: {
        Swiper,
        SwiperSlide,
        EpiLink
    },
    data() {
        return {
            imageParams: '',
            swiperOptions: {
                loop: this.model.articles.length > 1,
                centeredSlides: true,
                effect: 'fade',
                autoHeight: false,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '#sliderPagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '#sliderNextButton',
                    prevEl: '#sliderPrevButton',
                },
            }
        };
    },
    computed: {
        swiper() {
            return this.$refs.slider.$swiper;
        }
    },
    methods: {
        log(obj) {
            console.log(obj);
        },
        getImageParams() {
            return 'scale=both&width=1536&height=410&mode=crop&anchor=middelcenter';
        }
    },
};
</script>

<style scoped>

</style>
