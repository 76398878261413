var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "swiper",
          { ref: "slider", attrs: { options: _vm.swiperOptions } },
          [
            _vm._l(_vm.groups, function (group, gindex) {
              return _c(
                "swiper-slide",
                {
                  key: gindex,
                  staticClass:
                    "flex flex-wrap p-12 items-center justify-center w-full",
                },
                _vm._l(group, function (course, cindex) {
                  return _c(
                    "div",
                    {
                      key: cindex,
                      staticClass: "xl:w-1/5 lg:w-1/2 w-full h40",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mx-2 mt-2 bg-white px-10 pt-10 pb-8 h-60 hover:text-red-600",
                        },
                        [
                          _c(
                            "a",
                            { attrs: { href: course.url, target: "_self" } },
                            [
                              _c(
                                "div",
                                { staticClass: "h-1/2 border-b-4 pb-2 flex" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "self-end font-semibold" },
                                    [_vm._v(_vm._s(course.name))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              course.isCourseOnRequest
                                ? _c("div", { staticClass: "h-1/2 pt-2" }, [
                                    _c(
                                      "span",
                                      { staticClass: "inline-block align-top" },
                                      [_vm._v(_vm._s(course.duration))]
                                    ),
                                  ])
                                : _c("div", { staticClass: "h-1/2 pt-2" }, [
                                    _c(
                                      "span",
                                      { staticClass: "inline-block align-top" },
                                      [_vm._v(_vm._s(course.date))]
                                    ),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "relative mx-2 mb-2 bg-white" },
                        [
                          course.tag
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "absolute bottom-0 right-0 bg-orange-500 py-1 px-2 rounded-r-none rounded-t text-right uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(course.tag) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            }),
            _vm._v(" "),
            _vm.groups.length > 1
              ? _c("div", {
                  staticClass: "swiper-button-prev text-white mr-8",
                  attrs: { slot: "button-prev", id: "courseSliderPrevButton" },
                  slot: "button-prev",
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.groups.length > 1
              ? _c("div", {
                  staticClass: "swiper-button-next text-white ml-8",
                  attrs: { slot: "button-next", id: "courseSliderNextButton" },
                  slot: "button-next",
                })
              : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.groups.length > 1
      ? _c("div", {
          staticClass: "relative pb-4 flex justify-center",
          attrs: { slot: "pagination", id: "courseSliderPagination" },
          slot: "pagination",
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }