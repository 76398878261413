var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass:
        "breadcrumbs flex flex-wrap px-4 md:px-0 pl-0 pt-3 md:py-3 print:hidden",
      class: [_vm.className],
    },
    _vm._l(_vm.items, function (item, index) {
      return _c("li", { key: item.url, staticClass: "relative mr-6" }, [
        _c("a", { staticClass: "hover:underline", attrs: { href: item.url } }, [
          index === 0
            ? _c("span", [_vm._v("Forside")])
            : _c("span", [_vm._v(_vm._s(item.text))]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }