<template>
    <div class="mt-12 mb-20">
        <div v-if="dataLoaded">
            <LinedHeader :text="lineText" />
            <ul class="md:flex md:flex-wrap text-left content-evenly -m-2">
                <li v-for="result in results" :key="result.url" class="w-full md:w-1/2 xl:w-1/4 p-2">
                    <div class="bg-white shadow-md hover:shadow-sm rounded relative group h-full">
                        <img :src="`${result.ImageUrl}?${imageParams}&${result.ImageResizerParams}`" :alt="result.Name" />
                        <div class="p-4">
                            <h3 class="text-base font-bold">
                                {{ result.Name }}
                            </h3>
                        </div>
                        <a :href="result.Url"
                           :title="result.Name"
                           class="absolute w-full h-full top-0 left-0">
                        </a>
                    </div>
                </li>
            </ul>
            <div class="text-right my-4 w-full" v-if="latestPublishedPageUrl">
                <EpiLink :url="latestPublishedPageUrl" className="inline-block text-lg underline text-blue-500 hover:text-blue-600">
                    Vis flere &#10095;
                </EpiLink>
            </div>
        </div>
        <div v-else>
            <LoadingSpinner size="2x" />
        </div>
        <div v-if="error">Noe gikk galt</div>
    </div>
</template>

<script>
import api from '@/Scripts/api/api';
import LoadingSpinner from '@/Scripts/components/atoms/LoadingSpinner/loadingSpinner.vue';
import EpiLink from '@/Scripts/components/atoms/EpiLink/index.vue';
import LinedHeader from '@/Scripts/components/atoms/LinedHeader/LinedHeader.vue';

export default {
    props: ['model'],
    components: {
        LoadingSpinner,
        EpiLink,
        LinedHeader,
    },
    data() {
        return {
            dataLoaded: false,
            error: false,
            results: [],
            imageParams: 'scale=both&width=576&height=359',
            latestPublishedPageUrl: this.model.latestPublishedPageUrl.value.url,
            lineText: 'Siste aktuelle saker',
        };
    },
    created() {
        this.getLatestPublications(this.model);
    },
    methods: {
        getLatestPublications(model) {
            const parameters = {
                size: model.numberOfItems ? model.numberOfItems : 8,
                page: 1,
                siteid: this.$store.getters.getSiteId
            };
            api.getLatestPublications(parameters).then(response => {
                this.dataLoaded = true;
                this.results = response.data.SearchResult;
            });
        }
    }
};
</script>
