var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeMenu,
          expression: "closeMenu",
        },
      ],
      staticClass: "c_main-menu relative",
      class: [_vm.menuOpen ? "c_main-menu--open" : ""],
    },
    [
      _c(
        "button",
        { staticClass: "text-white mt-1", on: { click: _vm.toggleMenu } },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-fw",
            attrs: { icon: _vm.icon },
          }),
          _c("span", { staticClass: "pl-1.5" }, [_vm._v("MENY")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "absolute z-50 mt-2 bg-tundora-600 w-80 linear transition-scale origin-top duration-200 overflow-hidden",
          class: [_vm.menuOpen ? "shadow-2xl menuOpened" : "menuClosed"],
        },
        [
          _c(
            "ul",
            { staticClass: "list-none m-0 p-0" },
            _vm._l(_vm.menu, function (item) {
              return _c("Item", { key: item.url, attrs: { item: item } })
            }),
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }