var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", { staticClass: "uppercase text-base mb-4" }, [
      _vm._v("\n        Kontakt oss\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex flex-row items-start mb-4" }, [
      _c(
        "div",
        { staticClass: "pr-2" },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-lg",
            attrs: { icon: "building" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex-col" }, [
        _c("div", [_vm._v(_vm._s(_vm.model.address))]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.model.zipAndCity))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex flex-row items-start mb-4" }, [
      _c(
        "div",
        { staticClass: "pr-2" },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-lg",
            attrs: { icon: "phone-alt" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex-col" }, [
        _c(
          "a",
          {
            staticClass: "underline text-white hover:text-blue-500",
            attrs: { href: _vm.model.phone.href },
          },
          [
            _c(
              "span",
              { staticClass: "underline text-white hover:text-red-500" },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.model.phone.text) +
                    "\n                "
                ),
              ]
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex flex-row items-start mb-4" }, [
      _c(
        "div",
        { staticClass: "pr-2" },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-lg",
            attrs: { icon: "envelope" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex-col" }, [
        _c(
          "a",
          {
            staticClass: "underline text-white hover:text-blue-500",
            attrs: { href: _vm.model.email.href },
          },
          [
            _c(
              "span",
              { staticClass: "underline text-white hover:text-red-500" },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.model.email.text) +
                    "\n                "
                ),
              ]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }