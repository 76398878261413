var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.allChildren, function (child) {
      return _c(
        "button",
        {
          key: `child-${child.id}`,
          staticClass: "flex pb-2 w-full items-center hover:font-semibold",
          class: child.id === _vm.currentChild.id ? "font-bold" : "",
          on: {
            click: function ($event) {
              return _vm.click(child)
            },
          },
        },
        [
          child.id === _vm.currentChild.id
            ? _c("font-awesome-icon", {
                staticClass: "mr-2 fa-sm",
                attrs: { icon: "chevron-right" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(child.title))]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }