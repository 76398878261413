var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultTemplate", { attrs: { model: _vm.model } }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("Breadcrumbs", {
          attrs: {
            breadcrumbs: _vm.model.breadcrumbs,
            className: "text-grey-400",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "block lg:flex" }, [
          _c("div", { staticClass: "w-full lg:w-3/4 pr-0 lg:pr-8" }, [
            _c("div", { staticClass: "py-5 mt-1.5" }, [
              _c("h1", [_vm._v(_vm._s(_vm.model.name))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-wrap" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "MagazineImage",
                      expression: "'MagazineImage'",
                    },
                  ],
                },
                [
                  _vm.model.magazineImage
                    ? _c("img", {
                        staticClass: "main-body my-4 article pt-4 mr-8",
                        attrs: {
                          src: `${_vm.model.magazineImage}?scale=both&width=310&height=436&mode=crop&anchor=topleft`,
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", {}, [
                _vm.model.mainBody
                  ? _c("div", {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "MainBody",
                          expression: "'MainBody'",
                        },
                      ],
                      staticClass: "main-body my-4 article pt-4",
                      attrs: { id: "main-body" },
                      domProps: { innerHTML: _vm._s(_vm.model.mainBody) },
                    })
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4" }, [
              _c("span", [
                _vm._v("Endret dato: " + _vm._s(_vm.model.lastChanged)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("aside", { staticClass: "w-full lg:w-1/4 pr-0 lg:pl-4 mb-4" }, [
            _c("div", { staticClass: "py-5 mt-1.5" }, [
              _vm.model.documents
                ? _c("div", { staticClass: "mt-4" }, [
                    _c("span", { staticClass: "font-semibold mb-2 mt-4" }, [
                      _vm._v("Dokumenter"),
                    ]),
                    _vm._v(" "),
                    _vm.model.documents.length > 0
                      ? _c("div", [
                          _c(
                            "dl",
                            {
                              directives: [
                                {
                                  name: "epi-edit",
                                  rawName: "v-epi-edit",
                                  value: "Documents",
                                  expression: "'Documents'",
                                },
                              ],
                            },
                            _vm._l(_vm.model.documents, function (item) {
                              return _c(
                                "dd",
                                { key: item.title, staticClass: "pt-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-grey-500 hover:text-red-500",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: item.url,
                                            target: item.target,
                                            title: item.text,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "flex" }, [
                                            _c(
                                              "span",
                                              { staticClass: "mr-2" },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticStyle: {
                                                    "margin-top": "6px",
                                                  },
                                                  attrs: { icon: "file-alt" },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                    " +
                                                    _vm._s(item.text) +
                                                    "\n                                                "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.publications
                ? _c("div", { staticClass: "mt-4" }, [
                    _c("span", { staticClass: "font-semibold mb-2 mt-4" }, [
                      _vm._v("Publikasjoner"),
                    ]),
                    _vm._v(" "),
                    _vm.model.publications.length > 0
                      ? _c("div", [
                          _c(
                            "dl",
                            {
                              directives: [
                                {
                                  name: "epi-edit",
                                  rawName: "v-epi-edit",
                                  value: "Publications",
                                  expression: "'Publications'",
                                },
                              ],
                            },
                            _vm._l(_vm.model.publications, function (item) {
                              return _c(
                                "dd",
                                { key: item.title, staticClass: "pt-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-grey-500 hover:text-red-500",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: item.url,
                                            target: item.target,
                                            title: item.text,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "flex flex-row" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(item.text) +
                                                      "\n                                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }