/**
 * The module responsible for handling app-wide context state that is
 * interesting for several components that otherwise doesn't share state.
 */

//mutations for the appContext module
export const SHOW_MODAL = 'appContext/SHOW_MODAL';
export const HIDE_MODAL = 'appContext/HIDE_MODAL';

const state = {
    modalShowing: false,
    siteId: '',
    pageId: ''
};

const mutations = {
    [SHOW_MODAL](state) {
        state.modalShowing = true;
    },
    [HIDE_MODAL](state) {
        state.modalShowing = false;
    },
    SET_SITEID(state, siteId) {
        state.siteId = siteId;
    },
    SET_PAGEID(state, pageId) {
        state.pageId = pageId;
    }
};

const actions = {
    setSiteId({ commit }, siteId) {
        commit('SET_SITEID', siteId);
    },
    setPageId({ commit }, pageId) {
        commit('SET_PAGEID', pageId);
    }
};

export default {
    state,
    mutations,
    actions
};
