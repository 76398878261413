var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultTemplate",
    { staticClass: "bg-gray-100", attrs: { model: _vm.model } },
    [
      _c("div", { staticClass: "container pb-4" }, [
        _c(
          "div",
          {},
          [
            _c("Breadcrumbs", {
              attrs: {
                breadcrumbs: _vm.model.breadcrumbs,
                className: "text-grey-400",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "block lg:flex" }, [
          _c("div", { staticClass: "w-full lg:w-3/4 pr-0 lg:pr-4" }, [
            _c("div", { staticClass: "py-5 mt-1.5" }, [
              _c("h1", [_vm._v(_vm._s(_vm.model.name))]),
            ]),
            _vm._v(" "),
            _vm.model.mainBody || _vm.isEditable
              ? _c("div", {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "MainBody",
                      expression: "'MainBody'",
                    },
                  ],
                  staticClass: "main-body my-4 article",
                  attrs: { id: "main-body" },
                  domProps: { innerHTML: _vm._s(_vm.model.mainBody) },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.dataLoaded
              ? _c(
                  "div",
                  [
                    _vm.items.length > 0
                      ? _c(
                          "ul",
                          _vm._l(_vm.items, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: _vm.$uuid.v4(index),
                                staticClass:
                                  "flex mb-4 border-t-6 bg-white relative",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "absolute top-0 left-0 h-full w-full",
                                    attrs: { href: item.Url, title: "Les mer" },
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(
                                        "\n                                    Les mer\n                                "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "w-1/4 py-4 md:py-0" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: `${
                                          item.ImageUrl
                                        }?${_vm.getImageParams()}`,
                                        alt: item.Name,
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-3/4 p-4" }, [
                                  _c("h3", [_vm._v(_vm._s(item.Name))]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "py-2 text-sm text-grey-300",
                                      },
                                      [
                                        _vm._v(
                                          "[Publisert " +
                                            _vm._s(item.PublishDate) +
                                            "]"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", {
                                      staticClass: "text-sm pb-2",
                                      domProps: { innerHTML: item.Extract },
                                    }),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _c(
                          "div",
                          [
                            _c("message-box", {
                              attrs: {
                                title: "Ingen treff",
                                text: "Ingen aktuelle artikler å vise",
                                type: "info",
                              },
                            }),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _vm.totalPages > 1
                      ? _c("Pagination", {
                          attrs: {
                            currentPage: _vm.currentPage,
                            totalPages: _vm.totalPages,
                          },
                          on: { change: _vm.getItems },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "my-12" },
                  [_c("LoadingSpinner", { attrs: { size: "2x" } })],
                  1
                ),
          ]),
          _vm._v(" "),
          _c("aside", { staticClass: "w-full lg:w-1/4 pr-0 lg:pl-4 mb-4" }, [
            _c("div", { staticClass: "p-5 mb-6" }, [
              _c("dl", { staticClass: "text-sm" }, [
                _c(
                  "dt",
                  [
                    _c("ListFilter", {
                      attrs: {
                        allChildren: _vm.model.children,
                        currentChild: _vm.currentChild,
                      },
                      on: { setFilterChild: _vm.setFilterChild },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }