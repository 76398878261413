<template>
    <DefaultTemplate :model="model" class="bg-gray-100">
        <div class="container pb-4">
            <Breadcrumbs :breadcrumbs="model.breadcrumbs" className="text-grey-400" />
            <div class="block lg:flex">
                <div class="w-full lg:w-3/4 pr-0 lg:pr-8">
                    <!-- Overskrift -->
                    <div v-epi-edit="'Name'" class="py-5 mt-1.5 ">
                        <h1>{{model.heading}}</h1>
                    </div>
                    <!-- Bilde -->
                    <div v-epi-edit="'Image'" v-if="model.image || inEditMode">
                        <img :src="`${model.image}?width=1152`"
                             :alt="model.imageDescription"
                             class="w-full" />
                    </div>
                    <!-- Bildebeskrivelse -->
                    <div v-epi-edit="'ImageDescription'" v-if="model.image || inEditMode">
                        <p v-if="model.imageDescription"
                           v-text="model.imageDescription"
                           class="text-xs"></p>
                    </div>
                    <!-- Ingress -->
                    <div v-if="model.ingress || inEditMode" v-epi-edit="'Ingress'" id="ingress" class="main-body my-4 article pt-4" v-html="model.ingress" />
                    <!-- MainBody -->
                    <div v-if="model.mainBody || inEditMode" v-epi-edit="'MainBody'" id="main-body" class="main-body my-4 article pt-4" v-html="model.mainBody" />
                    <!-- LastChanged-->
                    <div class="mt-4 flex">
                        <span v-if="model.published" class="py-2 text-sm text-grey-300">Publisert: {{model.published}},&nbsp;</span>
                        <span v-if="model.lastChanged" class="py-2 text-sm text-grey-300">endret: {{model.lastChanged}}</span>
                    </div>
                    <!-- Aktuelle saker-->
                    <div if="inEditMode" class="my-4">
                        <EpiProperty propertyName="ShowList" displayText="Vis aktuelle saker" />
                    </div>
                    <div v-if="model.showList">
                        <div class="py-5 mt-6 border-t">
                            <h2>Aktuelle saker</h2>
                        </div>
                        <ul v-if="model.articles">
                            <li v-for="article in model.articles"
                                :key="article.id"
                                class="flex mb-4 bg-white border relative">
                                <a :href="article.url"
                                   class="absolute top-0 left-0 h-full w-full"
                                   title="Les mer">
                                    <span class="sr-only">
                                        Les mer
                                    </span>
                                </a>
                                <div class="w-1/4 py-4 md:py-0">
                                    <img :src="`${article.image}?${getImageParams()}`"
                                         :alt="article.name" />
                                </div>
                                <div class="w-3/4 p-4">
                                    <h3>{{ article.name }}</h3>
                                    <div>
                                        <p class="py-2 text-sm text-grey-300">[Publisert {{article.published}}]</p>
                                        <p class="text-sm pb-2" :inner-html.prop="article.ingress" />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <aside class="w-full lg:w-1/4 pr-0 lg:pl-4 mb-4 md:pt-14 pt-6">
                    <div class="py-5 mt-1.5 ">
                        <!-- H�yrebilde -->
                        <div v-epi-edit="'ImageRight'" v-if="model.imageRight || inEditMode">
                            <img :src="model.imageRight"
                                    class="w-full" />
                        </div>
                        <!-- Relevante kurs-->
                        <div v-if="model.relevantCourses || inEditMode" class="mt-4 bg-white">
                            <div v-epi-edit="'RelevantCoursesHeading'" class="text-xl p-4 uppercase mb-2">
                                {{model.relevantCoursesHeading}}
                            </div>
                            <ul v-epi-edit="'RelevantCourses'">
                                <li v-if="model.relevantCourses.length > 0" v-for="item in model.relevantCourses" :key="item.id" class="py-2 px-4 bg-white">
                                    <a :href="item.url" target="_self" :title="item.name">
                                        <div class="flex flex-row">
                                            <div class="flex flex-col flex-wrap truncate mr-2">
                                                <span class="text-grey-300 text-xs">{{item.date}}</span>
                                                <span class="text-grey-500 hover:text-red-500 hover:underline">{{item.name}}</span>
                                                <span class="text-grey-300 text-xs">{{item.location}}</span>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="bg-gray-300 h-px my-2"></div>
                                </li>
                            </ul>
                            <div v-if="model.courseListUrl" class="w-full text-right p-4 text-grey-500 hover:text-red-500 hover:underline">
                                <a :href="model.courseListUrl" target="_self">Vis kursoversikt &#x2192;</a>
                            </div>
                        </div>
                        <!-- Nettsteder -->
                        <div v-if="model.resourcePages || inEditMode" class="mt-4">
                            <span v-epi-edit="'ResourcePagesHeading'" class="font-semibold mb-2 mt-4">
                                {{model.resourcePagesHeading}}
                            </span>
                            <div class="bg-gray-300 h-px my-2"></div>
                            <ul v-epi-edit="'ResourcePages'">
                                <li v-if="model.resourcePages.length > 0" v-for="item in model.resourcePages" :key="item.id" class="pt-2">
                                    <div class="text-grey-500 hover:text-red-500">
                                        <a :href="item.url" :target="item.target" :title="item.text">
                                            <div class="flex flex-row">
                                                <div class="flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2">
                                                    {{item.text}}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- Dokumenter -->
                        <div v-if="model.resourceDocuments || inEditMode" class="mt-4">
                            <span v-epi-edit="'ResourceDocumentsHeading'" class="font-semibold mb-2 mt-4">
                                {{model.resourceDocumentsHeading}}
                            </span>
                            <div class="bg-gray-300 h-px my-2"></div>
                            <ul v-epi-edit="'ResourceDocuments'">
                                <li v-if="model.resourceDocuments.length > 0" v-for="item in model.resourceDocuments" :key="item.id" class="pt-2">
                                    <div class="text-grey-500 hover:text-red-500">
                                        <a :href="item.url" :target="item.target" :title="item.text">
                                            <div class="flex">
                                                <span class="mr-2">
                                                    <font-awesome-icon style="margin-top: 6px;" icon="file-alt" />
                                                </span>
                                                <div class="flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2">
                                                    {{item.text}}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- Arkiv -->
                        <div if="inEditMode" class="my-4">
                            <EpiProperty propertyName="ShowArchive" displayText="Vis arkiv" />
                        </div>
                        <div v-if="model.showArchive || inEditMode">
                            <div v-if="model.archive || inEditMode" class="mt-4">
                                <span v-epi-edit="'ArchiveHeading'" class="font-semibold mb-2 mt-4">
                                    {{model.archiveHeading}}
                                </span>
                                <div class="bg-gray-300 h-px my-2"></div>
                                <div v-if="model.archive.length > 0">
                                    <ul>
                                        <li v-for="item in model.archive" :key="item.id" class="pt-2">
                                            <div class="text-grey-500 hover:text-red-500">
                                                <a :href="item.url" :target="item.target" :title="item.name">
                                                    <div class="flex">
                                                        <span class="mr-2">
                                                            <font-awesome-icon icon="caret-right" />
                                                        </span>
                                                        <div class="flex-1 text-grey-500 hover:text-red-500 underline truncate mr-2">
                                                            {{item.name}}
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import { mapState } from 'vuex';
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import EpiProperty from '@/Scripts/components/EpiProperty.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
        EpiProperty,
    },
    computed: mapState({
        inEditMode: state => state.epiContext.inEditMode
    }),
    methods: {
        open(url) {
            console.log(url);
            window.open(url, '_blank');
        },
        getImageParams() {
            return 'scale=both&width=278&height=140&mode=crop&anchor=middelcenter';
        },
    },
};
</script>
