var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "ContentArea" },
    _vm._l(_vm.model, function (block, index) {
      return _c(
        "div",
        { key: index },
        [
          _c("BlockComponentSelector", {
            attrs: {
              "data-epi-block-id": _vm.isEditable ? block.contentLink.id : null,
              model: block,
              className: "o-section",
            },
          }),
          _vm._v(" "),
          _vm.displaySeperator && index + 1 < _vm.model.length
            ? _c("span", { staticClass: "u-seperator o-section" })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }