var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "w-2/3 md:w-auto relative flex ml-4 items-center",
      attrs: { method: "get", action: _vm.searchPageUrl },
    },
    [
      _c(
        "button",
        {
          staticClass: "absolute opacity-75 pl-2",
          class: [_vm.hasFocus ? "text-black" : "text-white"],
          attrs: { type: "submit" },
          on: {
            focus: function ($event) {
              return _vm.toggleFocus(true)
            },
            blur: function ($event) {
              return _vm.toggleFocus(false)
            },
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "search" } })],
        1
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.query,
            expression: "query",
          },
        ],
        staticClass:
          "rounded-full pl-7 pr-3 py-1 text-sm color-black placeholder-opacity-25 focus:outline-none focus:bg-white text-white focus:text-black",
        class: [
          _vm.hasFocus
            ? "bg-white placeholder-black " + _vm.className
            : "bg-search placeholder-white " + _vm.className,
        ],
        attrs: {
          type: "search",
          name: "query",
          autocomplete: "off",
          placeholder: "Søk på tittel, forfatter, tema o.l.",
        },
        domProps: { value: _vm.query },
        on: {
          focus: function ($event) {
            return _vm.toggleFocus(true)
          },
          blur: function ($event) {
            return _vm.toggleFocus(false)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.query = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }