var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "justify-around flex flex-row items-center" },
    [
      _c(
        "epi-link",
        {
          directives: [{ name: "tippy", rawName: "v-tippy" }],
          staticClass:
            "inline-block text-white opacity-75 hover:opacity-100 mr-3",
          attrs: {
            url: "https://www.facebook.com/korusnord",
            target: "_new",
            title: "Facebook (åpnes i ny fane)",
            content: "Facebook (åpnes i ny fane)",
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-fw",
            attrs: { icon: ["fab", "facebook-square"] },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [{ name: "tippy", rawName: "v-tippy" }],
          staticClass:
            "inline-block text-white opacity-75 hover:opacity-100 mr-3",
          attrs: { content: "Skriv ut denne siden" },
          on: {
            click: function ($event) {
              return _vm.print()
            },
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-fw",
            attrs: { icon: "print" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [{ name: "tippy", rawName: "v-tippy" }],
          staticClass:
            "inline-block text-white opacity-75 hover:opacity-100 mr-3",
          attrs: {
            id: "speaker",
            onClick: "vFactClient.leseAct()",
            content: "Lytt til tekst",
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-fw",
            attrs: { icon: "volume-up" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [{ name: "tippy", rawName: "v-tippy" }],
          staticClass:
            "inline-block text-white opacity-75 hover:opacity-100 mr-3 cursor-pointer",
          attrs: {
            content:
              "Hold Ctrl-tasten nede (Cmd på Mac).<br/>Trykk på + for å forstørre eller - for å forminske.",
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-fw",
            attrs: { icon: "text-height" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          directives: [{ name: "tippy", rawName: "v-tippy" }],
          staticClass:
            "inline-block text-white opacity-75 hover:opacity-100 mr-3",
          attrs: {
            href: _vm.model.login.href,
            target: _vm.model.login.target,
            title: _vm.model.login.text,
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-fw",
            attrs: { icon: _vm.model.login.icon },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }