<template>
    <DefaultTemplate :model="model">
        <div class="bg-gray-100 pb-10">
            <div class="container">
                <Breadcrumbs :breadcrumbs="model.breadcrumbs" className="text-black" />
                <div class="w-full">
                    <div class="bg-white p-5 my-1.5">
                        <div>
                            <form @submit.prevent="search()" class="flex mb-4">
                                <input v-model="query" class="w-full bg-gray-100 flex-1 px-3" />
                                <button type="submit" class="py-2 px-4 text-white uppercase bg-orange-500 hover:bg-orange-300">
                                    <font-awesome-icon icon="search" />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <LoadingSpinner v-if="loading" size="2x" />
                <message-box v-if="error && !loading" title="Feil" text="En feil har oppstått i forsøket på å søke. Hvis feilen gjentar seg, vennligst ta kontakt med oss for hjelp til å finne det innholdet du leter etter." type="alert" />
                <message-box v-if="searchResult.length === 0 && query && !loading" title="Ingen treff" text="Vi kan ikke finne noen treff i det du søker etter" type="info" />
                <div v-if="searchResult.length > 0 && !loading" class="md:flex mt-2">
                    <div class="w-full">
                        <div class="md:flex flex-col">
                            <ol class="divide-y bg-white">
                                <li v-for="result in searchResult"
                                    :key="result.Url"
                                    class="p-4">
                                    <h3 class="md:flex md:justify-between text-base mb-2 md:items-start">
                                        <a :href="result.Url"
                                           :title="result.Name"
                                           class="text-blue-500 underline hover:text-red-500">
                                            {{ result.Name }}
                                        </a>
                                    </h3>
                                    <div v-html="result.extract"></div>
                                </li>
                            </ol>
                            <Pagination v-if="Math.ceil(searchResultCount / pagination.pages.pageSize) > 1" :currentPage="pagination.pages.page" :totalPages="Math.ceil(searchResultCount / pagination.pages.pageSize)" @change="paginatePages" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import api from '@/Scripts/api/api';
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import LoadingSpinner from '@/Scripts/components/atoms/LoadingSpinner/loadingSpinner.vue';
import Pagination from '@/Scripts/components/organisms/Pagination/pagination.vue';
import MessageBox from '../atoms/Messagebox/MessageBox.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
        LoadingSpinner,
        Pagination,
        MessageBox,
    },
    data() {
        return {
            loading: false,
            error: false,
            query: null,
            searchResult: [],
            totalHits: 0,
            searchResultCount: 0,
            pagination: {
                pages: {
                    page: 1,
                    pageSize: 10
                },
                files: {
                    page: 1,
                    pageSize: 10
                }
            },
        };
    },
    methods: {
        search() {
            let site = this.model.sites.filter(site => {
                return site.current === true;
            });
            this.loading = true;
            this.error = false;
            const body = {
                words: this.query,
                siteId: site[0].id,
                publishDate: this.selectedTimespan,
                pageNumber: this.pagination.pages.page - 1,
                pageSize: 10,
                orderByResultDate: true,
            };
            api.postSearch(body).then(response => {
                this.loading = false;
                const data = response.data;
                if (!data.error) {
                    console.log(data.SearchResult);
                    this.searchResult = data.SearchResult;
                    this.searchResultCount = data.NumHitsPages;
                }
            }).catch(error => {
                this.loading = false;
                this.error = true;
                console.log(error);
            });
        },
        paginatePages(direction) {
            if (direction < this.pagination.pages.page) {
                // Prev
                this.pagination.pages.page--;
            } else {
                // Next
                this.pagination.pages.page++;
            }
            this.search();
        },
        paginateFiles(direction) {
            if (direction < this.pagination.pages.page) {
                // Prev
                this.pagination.pages.page--;
            } else {
                // Next
                this.pagination.pages.page++;
            }
            this.search();
        },
    },
    mounted() {
        if (this.$route.query && this.$route.query.query) {
            this.query = this.$route.query.query;
            this.search();
        }
    },
};
</script>
